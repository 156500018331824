import React, {FC} from 'react';
import {Control, useController} from 'react-hook-form';
// @ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface Props {
  name: string;
  placeholder?: string;
  className?: string;
  control: Control<any>;
  defaultValue?: string | number | string[];
  containerClassName?: string;
  error?: string;
  rows?: number;
}

const TextEditor: FC<Props> = ({name, control, defaultValue}) => {
  const {
    field: {onChange, value},
    fieldState: {error}
  } = useController({name, control, defaultValue});

  return (
    <div className="my-4">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(event: any, editor: any) => {
          onChange(editor.getData());
        }}
        config={{
          language: 'en'
        }}
      />
      {error && <span className="text-red-500 text-sm mx-4 ">{error?.message}</span>}
    </div>
  );
};

export default TextEditor;
