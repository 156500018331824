import React, {useState, useEffect, useRef, ElementRef, FC, useCallback} from 'react';
import {Modal} from 'components';
import {homeTabs} from 'assets';
import {LoginForm, CompaniesList, ProductsList} from 'containers';
import {TabHeader, TabContainer} from 'components';
import {useLocation, useHistory} from 'react-router-dom';

const TabsContainer: FC = () => {
  const modalRef = useRef<ElementRef<typeof Modal>>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.hash) {
      setActiveTab(parseInt(location?.hash.split('#')[1], 10));
    }
  });

  const showLoginModal = useCallback(() => {
    if (modalRef?.current?.open) modalRef.current.open();
  }, []);

  const closeLoginModal = useCallback(() => {
    if (modalRef?.current?.close) modalRef.current.close();
  }, []);

  return (
    <>
      <TabHeader
        activeIndex={activeTab}
        onIndexChange={(i) => {
          setActiveTab(i);
          history.push(`#${i}`);
        }}
        className="my-[20px]"
        items={homeTabs}
      />
      <TabContainer activeIndex={activeTab}>
        <CompaniesList onShowLogin={showLoginModal} />
        <ProductsList onShowLogin={showLoginModal} />
      </TabContainer>
      <Modal ref={modalRef}>
        <LoginForm isModal onSubmitModal={closeLoginModal} />
      </Modal>
    </>
  );
};

export default TabsContainer;
