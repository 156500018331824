import replace from 'lodash/replace';
import {AxiosError} from 'axios';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import {onlineManager} from 'react-query';

export const urlGenerator = (url: string, version?: number | string): string =>
  version ? replace(`v${version}/${url}`, '//', '/') : replace(`${url}`, '//', '/');

export const getLangSearchParam = (url: string): string => {
  const lang = new URL(window.location.href).searchParams.get('lang');
  if (!lang || lang?.toString().length !== 2) return url;
  return `${url}?lang=${lang}`;
};

export const ResponseErrorHandler = (error: AxiosError): string | null => {
  const response: any = error?.response?.data;
  const status: any = error?.response?.status;
  let message: string = '';

  if (status === 422) message = values(response?.data).join('\n');
  else if (status === 404 || status === 419 || status === 429 || status === 403)
    message = response?.message || 'Sorry, An Error Happened';
  else if (error.code === 'ECONNABORTED') message = 'server is busy';
  else if (!onlineManager.isOnline()) message = 'you are not connect to internet';
  else if (status === 500 || (error.isAxiosError && isNil(error?.response)))
    message = response?.message || 'Sorry, An Error Happened In Our Server :(';
  if (!isEmpty(message) && !error?.config?.headers?.silent) {
    return message;
  }
  return null;
};
