import DeletePrompt from 'components/modals/DeletePrompt';
import React, {ElementRef, FC, useCallback, useEffect, useRef} from 'react';
import {IoMdAddCircle} from 'react-icons/io';
import {TabItem} from 'components';
import map from 'lodash/map';
import type {TabItemProps} from 'types/common';

interface Props {
  tabs: TabItemProps[];
  onTabIndexChange(i: number): void;
  onNewTab(): void;
  selectedIndex: number;
}

const DynamicTabs: FC<Props> = ({tabs, selectedIndex, onNewTab, onTabIndexChange}) => {
  const modalRef = useRef<ElementRef<typeof DeletePrompt>>(null);

  useEffect(() => {
    if (selectedIndex) onTabIndexChange(0);
  }, [tabs]);

  const showDeleteModal = useCallback((params: object) => {
    if (modalRef?.current?.open) modalRef?.current?.open(params);
  }, []);

  return (
    <div>
      <div className=" flex overflow-y-auto custom__scroll items-center">
        {map(tabs, (tab, i) => (
          <TabItem
            isSelected={i === selectedIndex}
            onTabIndexChange={() => onTabIndexChange(i)}
            tab={tab}
            onDeleteRequest={showDeleteModal}
          />
        ))}
        <button type="button" className="cursor-pointer" onClick={onNewTab}>
          <IoMdAddCircle size={35} className="text-primary color-primary mx-2" />
        </button>
      </div>
      <DeletePrompt
        ref={modalRef}
        url="bookmarks/{id}"
        refetchQueries={['bookmarks']}
        title="Are You Sure You Want To Delete {name} ?"
      />
    </div>
  );
};
export default DynamicTabs;
