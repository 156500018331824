import React, {FC, useState} from 'react';
import {Control} from 'react-hook-form';
import clsx from 'clsx';
import {EditIconButton, TextBox} from '../index';

interface props {
  name: string;
  placeholder?: string;
  type?: 'text' | 'search' | 'number' | 'password' | 'email' | 'tel';
  defaultValue?: string | number | string[];
  className?: string;
  textClassName?: string;
  enableEdit?: boolean;
  control: Control<any>;
  containerClassName?: string;
}

const TextEnableEdit: FC<props> = ({
  name,
  defaultValue,
  type,
  control,
  textClassName,
  enableEdit,
  placeholder,
  className,
  containerClassName
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  return (
    <div className={clsx('flex justify-between', containerClassName)}>
      <div className={`${isEdit && enableEdit ? 'flex w-full' : 'hidden'}`}>
        <TextBox
          name={name}
          control={control}
          type={type}
          containerClassName="w-full"
          placeholder={placeholder}
          className={className}
          defaultValue={defaultValue}
        />
      </div>
      <p className={clsx('text-sm text-gray-600', textClassName, {hidden: isEdit && enableEdit})}>{defaultValue}</p>
      {enableEdit && !isEdit && <EditIconButton className="mx-8" onClick={() => setIsEdit(true)} />}
    </div>
  );
};

export default TextEnableEdit;
