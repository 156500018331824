import React, {FC} from 'react';
import {LinkButton, TextBox} from 'components';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {useUser, usePost} from 'hooks';
import {AuthLayout} from 'containers';
import type {LoginFormData} from 'types/auth';
import {useQueryClient} from 'react-query';

interface Props {
  isModal?: boolean;
  onSubmitModal?(): void;
}

const schema = yup.object().shape({
  email: yup.string().trim().email().required(),
  password: yup.string().required()
});

const LoginForm: FC<Props> = ({onSubmitModal = () => {}, isModal}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {handleSubmit, control} = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });
  const {setUser} = useUser();
  const postLogin = usePost({
    url: 'login',
    onSuccess(response: any, request: any) {
      setUser({...response, email: request.body.email, isLogin: true});
      queryClient.removeQueries();
      if (isModal && onSubmitModal) onSubmitModal();
      else history.replace('/');
    }
  });

  const onSubmit = (data: LoginFormData) => {
    postLogin.post(data);
  };

  return (
    <AuthLayout
      isModal={isModal}
      onSubmit={handleSubmit(onSubmit)}
      loading={postLogin?.isLoading}
      header={
        <p className={isModal ? 'mt-4 text-2xl py-4 text-center' : 'mt-16 text-2xl py-4 text-center'}>Welcome !</p>
      }
      footer={
        <div className="mt-8 flex-center flex-col">
          <LinkButton to="/auth/forget" primary link className="text-sm">
            Forget Password?
          </LinkButton>
          <div className="flex-center mt-4">
            <p className="text-sm text-gray-700 px-1">New To Companext? </p>
            <LinkButton to="/auth/register" primary link className="text-sm">
              Join Now!
            </LinkButton>
          </div>
        </div>
      }
      buttonText="Sign In"
      headerText="Don't miss your next opportunity. Sign in to stay updated or create a company page .">
      <TextBox placeholder="Email" containerClassName="w-full" name="email" control={control} />
      <TextBox
        placeholder="Password"
        containerClassName="w-full mt-2 mb-6"
        name="password"
        control={control}
        type="password"
      />
    </AuthLayout>
  );
};
export default LoginForm;
