import React from 'react';
import {useInfinite} from 'hooks';
import {ListFooter, BookmarkItemCard, FetchContainer} from 'components';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';

interface Props {
  tabID: number;
}

const BookmarkItemsList: React.FC<Props> = ({tabID}) => {
  const fetchBookmarksList = useInfinite({
    name: ['savedItems', tabID],
    url: 'saved_items/paginate',
    query: {bookmark_id: tabID},
    enabled: true
  });

  return (
    <FetchContainer
      isLoading={fetchBookmarksList?.isFetching && !fetchBookmarksList?.isFetchingNextPage}
      isError={fetchBookmarksList?.isError}
      onReFetch={fetchBookmarksList.refetch}>
      <div className="fade-in relative">
        {flatMap(fetchBookmarksList?.data, (bookmark: any) => (
          <BookmarkItemCard key={bookmark?.id} bookmark={bookmark} tabID={tabID} />
        ))}
        {isEmpty(fetchBookmarksList?.data) && (
          <div className="flex-center mt-16">
            <p className="text-sm text-gray-500">No Items found !</p>
          </div>
        )}
        <ListFooter
          onFetchMoreClick={fetchBookmarksList?.fetchNextPage}
          canFetchMore={fetchBookmarksList?.hasNextPage}
          isFetchingMore={fetchBookmarksList?.isFetchingNextPage}
        />
      </div>
    </FetchContainer>
  );
};

export default BookmarkItemsList;
