import React, {FC} from 'react';
import {Control, useController} from 'react-hook-form';
import clsx from 'clsx';

interface props {
  name: string;
  placeholder?: string;
  type?: 'text' | 'search' | 'number' | 'password' | 'email' | 'tel';
  defaultValue?: string | number | string[];
  className?: string;
  disabled?: boolean;
  control: Control<any>;
  containerClassName?: string;
  error?: string;
}

const TextBox: FC<props> = ({
  name,
  defaultValue,
  type,
  control,
  disabled,
  placeholder,
  className,
  containerClassName
}) => {
  const {
    field,
    fieldState: {error}
  } = useController({name, control, defaultValue});

  return (
    <div className={containerClassName}>
      <input
        {...field}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        className={clsx(
          'border rounded-full w-full h-8 px-4 py-4 text-sm transition duration-200',
          {'border-red-500': error?.message, 'border-gray-400 focus:border-primary': !error?.message},
          className
        )}
      />
      {error?.message && <p className="text-red-500 text-sm mx-4 first-letter:capitalize">{error?.message}</p>}
    </div>
  );
};

export default TextBox;
