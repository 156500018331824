import React, {FC} from 'react';
import {TextBox} from 'components';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {AuthLayout} from 'containers';
import type {ChangePasswordFormData} from 'types/auth';

interface Props {
  onCheck(data: ChangePasswordFormData): void;
  isLoading: boolean;
  isFromForget?: boolean;
}

const ChangePasswordForm: FC<Props> = ({isLoading, onCheck, isFromForget}) => {
  const schema = yup.object().shape({
    password: yup.string().required(),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords dont match')
      .required()
  });

  const {handleSubmit, control} = useForm<ChangePasswordFormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: ChangePasswordFormData) => onCheck(data);

  return (
    <AuthLayout
      headerText={isFromForget ? 'Reset Your Password' : 'Please set a password for your account.'}
      loading={isLoading}
      buttonText="Save"
      onSubmit={handleSubmit(onSubmit)}>
      <TextBox
        placeholder="password"
        containerClassName="w-full mb-4"
        name="password"
        type="password"
        control={control}
      />
      <TextBox
        placeholder="Repeat Password"
        containerClassName="w-full mb-4"
        name="repeatPassword"
        type="password"
        control={control}
      />
    </AuthLayout>
  );
};
export default ChangePasswordForm;
