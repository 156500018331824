import React, {FC} from 'react';
import {ServerError, Spinner} from 'components';

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  onReFetch(): void;
}

const FetchContainer: FC<Props> = ({isError, isLoading, onReFetch, children}) => {
  if (isLoading) return <Spinner center className="mt-16" />;
  if (isError) return <ServerError className="mt-16" onTryAgain={onReFetch} />;
  return <div>{children}</div>;
};

export default FetchContainer;
