import React, {ElementRef, useCallback, useRef} from 'react';
import {ProfileAvatar, ProfileMenu} from 'components';

const UserProfileButton: React.FC = () => {
  const profileMenuRef = useRef<ElementRef<typeof ProfileMenu>>(null);

  const openProfileMenu = useCallback(() => {
    if (profileMenuRef?.current?.open) profileMenuRef.current.open();
  }, []);

  return (
    <div className="relative">
      <ProfileAvatar onClick={openProfileMenu} />
      <ProfileMenu ref={profileMenuRef} />
    </div>
  );
};
export default UserProfileButton;
