import React, {FC, RefObject, useEffect, useRef} from 'react';

interface props {
  ref?: RefObject<any>;
  onOutsideClick: () => void;
}

const useOutsideDetect = ({ref, onOutsideClick}: props) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

const OutsideView: FC<props> = ({onOutsideClick, ref, children}) => {
  const wrapperRef = useRef(null);
  useOutsideDetect({
    ref: wrapperRef,
    onOutsideClick
  });

  return <div ref={wrapperRef}>{children}</div>;
};
export default OutsideView;
