import React, {FC} from 'react';

interface Props {
  title?: string;
  content?: string;
  isLink?: boolean;
  hide?: boolean;
}

const DescriptionItem: FC<Props> = ({title, content, isLink, children, hide}) => {
  if ((!content && !children) || hide) return null;
  return (
    <div className="md:flex my-4">
      <div className="flex-3">
        <p className="text-black">{title}</p>
      </div>
      <div className="flex-7">
        {isLink ? (
          <a target="_blank" href={content} rel="noopener noreferrer">
            {content}
          </a>
        ) : (
          <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
            {content}
          </p>
        )}
        {children && children}
      </div>
    </div>
  );
};

export default DescriptionItem;
