import React, {FC} from 'react';
import {useInfinite} from 'hooks';
import {ListFooter, SelectBox, ProductCard, FetchContainer} from 'components';
import map from 'lodash/map';
import type {CompanyDataApi} from 'types/company';
import type {ProductListItem} from 'types/products';

interface Props {
  data?: CompanyDataApi;
}

const ProductsTab: FC<Props> = ({data: companyData}) => {
  const fetchProductTab = useInfinite({
    name: ['company', companyData?.id, 'products'],
    url: 'companies/{id}/products',
    params: {id: companyData?.id},
    enabled: true
  });

  const selectTag = (tagSelected: any) => {
    fetchProductTab?.fetch(undefined, undefined, tagSelected?.value ? {tags_id: [tagSelected?.value]} : undefined);
  };

  return (
    <div className="fade-in">
      <div className="w-full flex justify-end">
        <div className="w-full md:w-1/2  lg:w-1/2 flex-center mt-4 mb-4">
          <SelectBox
            name="tags"
            url="companies/{id}/tags"
            urlName={['company', companyData?.id, 'tags']}
            params={{id: companyData?.id}}
            onChange={selectTag}
            isClearable
            mode="single"
            isSearchable={window.innerWidth > 768}
            placeholder="Select Category"
          />
        </div>
      </div>
      <FetchContainer
        onReFetch={fetchProductTab?.refetch}
        isError={fetchProductTab?.isError}
        isLoading={fetchProductTab?.isFetching && fetchProductTab?.isFetchingNextPage}>
        {map(fetchProductTab?.data, (product: ProductListItem) => (
          <ProductCard data={product} key={product.id} companyID={companyData?.id} />
        ))}
        <ListFooter
          onFetchMoreClick={fetchProductTab?.fetchNextPage}
          canFetchMore={fetchProductTab?.hasNextPage}
          isFetchingMore={fetchProductTab?.isFetchingNextPage}
        />
      </FetchContainer>
    </div>
  );
};
export default ProductsTab;
