import React, {ChangeEvent, FC, useCallback, useRef} from 'react';
import {Control, useController} from 'react-hook-form';
import {usePost} from 'hooks';
import {EditIconButton, Spinner} from 'components';
import {convertImageUrlToPath} from 'utils';
import first from 'lodash/first';

interface Props {
  name: string;
  type: string;
  disabled?: boolean;
  defaultValue?: string;
  control: Control<any>;
}

const AvatarInput: FC<Props> = ({name, type, defaultValue, control, disabled}) => {
  const uploaderRef: any = useRef();

  const {
    field: {onChange, value},
    fieldState: {error}
  } = useController({
    name,
    control,
    defaultValue: {
      path: convertImageUrlToPath(defaultValue),
      url: defaultValue
    }
  });

  const uploadImage = usePost({
    url: 'file-upload',
    isMultipart: true,
    onSuccess(response: any, request?: any) {
      onChange({path: response, file: request?.body?.get('file')});
    }
  });

  const changeImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e?.target?.files);
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      uploadImage.post(formData);
    }
  };

  const onClickImage = useCallback(() => {
    if (uploaderRef?.current?.click) uploaderRef.current.click();
  }, []);

  return (
    <div className="flex-3 flex-center flex-col mt-4 md:mt-0 ">
      <div className="relative w-32 h-32 " style={{marginTop: '-70px'}}>
        <input
          onChange={changeImageHandler}
          ref={uploaderRef}
          disabled={disabled}
          className="input"
          accept="image/x-png,image/jpeg,image/gif"
          type="file"
          style={{display: 'none'}}
        />
        <img
          src={value?.file ? URL.createObjectURL(value?.file) : value.url}
          alt="avatar"
          className="rounded-lg bg-white border w-24 h-24 md:w-32 md:h-32 object-cover"
        />
        {uploadImage?.isLoading && <Spinner className="absolute right-[40%] top-[45%]" />}
        {!disabled && <EditIconButton className="absolute t-0" style={{top: 10, right: 10}} onClick={onClickImage} />}
      </div>
      {error?.message && <p className="text-red-500 text-sm mx-4 ">{error?.message}</p>}
    </div>
  );
};

export default AvatarInput;
