import axios, {AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import type {responseProps} from 'types/request';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000
});

instance.interceptors.response.use((response: AxiosResponse): responseProps => {
  if (
    response.config.method !== 'get' &&
    !response?.config?.headers?.silent &&
    response?.config?.headers?.successMessage
  )
    toast.success(response?.config?.headers?.successMessage);

  return {
    data: response.data?.data,
    meta: response.data?.meta,
    schema: response.data?.schema,
    status: response.status,
    statusText: response.statusText
  };
});
export default instance;
