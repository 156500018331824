import React from 'react';
import {Button, Spinner} from 'components';
import {MdExpandMore} from 'react-icons/md';

interface Props {
  canFetchMore?: boolean;
  isFetchingMore?: boolean;
  onFetchMoreClick?(): void;
}

const ListFooter: React.FC<Props> = ({isFetchingMore, canFetchMore, onFetchMoreClick}) => {
  if (isFetchingMore) return <Spinner center className="py-4" />;
  if (canFetchMore)
    return (
      <div className="flex justify-center items-center mt-4">
        <Button onClick={onFetchMoreClick} className="flex-center">
          More
          <MdExpandMore />
        </Button>
      </div>
    );
  return null;
};

export default ListFooter;
