import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {FilterContext} from 'contexts';
import {useInfinite} from 'hooks';
import debounce from 'lodash/debounce';

function useFilter() {
  const history = useHistory();
  const {filters, setFilters} = useContext(FilterContext);

  const fetchCompanies = useInfinite({
    name: 'companies',
    url: 'companies/paginate',
    enabled: true
  });

  const fetchProducts = useInfinite({
    name: 'products',
    url: 'products/paginate',
    enabled: true
  });

  const addFilter = (newFilter: object, hasDeounce: boolean = false) => {
    fetchCompanies.fetch(undefined, {...(filters || {}), ...newFilter});
    fetchProducts.fetch(undefined, {...(filters || {}), ...newFilter});
    if (!hasDeounce) setFilters((prevState) => ({...(prevState || {}), ...newFilter}));
  };

  const debounceSearch = useCallback(
    debounce((text: string) => {
      addFilter({search: text?.length ? text : undefined}, true);
      if (history.location.pathname !== '/') history.push('/');
    }, 2000),
    []
  );

  const onSearch = (search: string) => {
    setFilters((prevState) => ({...(prevState || {}), ...{search: search?.length ? search : undefined}}));
    debounceSearch(search);
  };

  const clearFilter = () => {
    setFilters(undefined);
  };

  return {
    filters,
    setFilters,
    onSearch,
    debounceSearch,
    clearFilter,
    addFilter
  };
}

export default useFilter;
