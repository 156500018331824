import React, {FC} from 'react';
import {MdEdit} from 'react-icons/md';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const EditIconButton: FC<Props> = ({className, ...props}) => {
  return (
    <div
      {...props}
      style={{...props.style, width: 35, height: 35}}
      className={` rounded-full p-2 cursor-pointer flex justify-center items-center bg-blue-100 ${className}`}>
      <MdEdit size={17} color="#0278ae" />
    </div>
  );
};

export default EditIconButton;
