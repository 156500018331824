import React, {FC, useEffect} from 'react';
import {Control, useController} from 'react-hook-form';
import clsx from 'clsx';

interface Props {
  name: string;
  label?: string;
  defaultValue?: boolean;
  loading?: boolean;
  control: Control<any>;
}

const CheckBox: FC<Props> = ({name, label, control, defaultValue = false, children}) => {
  const {
    field,
    fieldState: {error}
  } = useController({name, control, defaultValue});

  useEffect(() => {
    if (defaultValue && defaultValue !== field.value) field.onChange(defaultValue);
  }, [defaultValue]);

  return (
    <div className="mr-4 mb-2 flex items-center">
      <input {...field} id={name} type="checkbox" value={field.value} className="absolute h-8 w-8 opacity-0" />
      <div
        className={`mr-2 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md border-2 ${
          error?.message ? 'border-red-400' : 'border-primary'
        } bg-white focus-within:border-primary-dark`}>
        <svg
          className={clsx('pointer-events-none h-3 w-3 fill-current text-blue-600', {hidden: !field.value})}
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#3abe9e" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <label htmlFor={name} className={`select-none px-2 text-sm ${error?.message ? 'text-rose-400' : ''}`}>
        {label || children}
      </label>
    </div>
  );
};

export default CheckBox;
