import React, {FC, MouseEvent} from 'react';
import clsx from 'clsx';

interface Props {
  title: string;
  isActive: boolean;
  onClick?: (event?: MouseEvent<HTMLElement>) => void;
}

const ViewTypeButton: FC<Props> = ({isActive, title, onClick}) => {
  return (
    <button
      type="button"
      className={clsx('border-2 md:border-4 text-sm md:text-base md:font-bold p-1 m-1', {
        'border-yellow-400 text-yellow-300': isActive,
        'border-white text-white': !isActive
      })}
      onClick={onClick}>
      {title}
    </button>
  );
};

export default ViewTypeButton;
