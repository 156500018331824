import React, {CSSProperties, FC, ReactNode} from 'react';
import {Spinner} from 'components';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

interface Props {
  children?: any;
  className?: string;
  style?: CSSProperties;
  to: string;
  primary?: boolean;
  link?: boolean;
  fill?: boolean;
  isLoading?: boolean;
  small?: boolean;
  icon?: ReactNode;
}

const Button: FC<Props> = ({
  children,
  className,
  primary,
  link,
  to,
  style,
  isLoading,
  fill = false,
  small = false,
  icon
}) => {
  return (
    <Link
      to={to}
      style={style}
      className={clsx(
        'rounded-full  cursor-pointer text-center text-md border-2 hover:text-secondary transition-all duration-200 ',
        {'text-xs p-1 md:px-2': small},
        {
          'text-white bg-primary hover:shadow-xl': fill,
          'bg-transparent  hover:border-secondary': !fill,
          'text-primary': primary && !fill
        },
        {'border-primary': primary},
        {'border-0 !p-0': link},
        {'px-4 md:px-4 py-2 md:py-3': !small},
        className
      )}>
      {isLoading ? (
        <Spinner color="text-white" />
      ) : (
        <>
          {icon && icon}
          {children}
        </>
      )}
    </Link>
  );
};

export default Button;
