import React, {FC} from 'react';
import {RiDeleteBin6Line} from 'react-icons/ri';
import clsx from 'clsx';

interface Props {
  onTabIndexChange(i: number): void;
  isSelected: boolean;
  onDeleteRequest(data: any): void;
  tab: any;
}

const TabItem: FC<Props> = ({onTabIndexChange, isSelected, tab, onDeleteRequest}) => {
  return (
    <div
      className={clsx('vendor-tab-item', {'border-primary': isSelected})}
      key={tab?.id}
      onClick={() => onTabIndexChange(tab?.id)}>
      {tab?.name}
      <div
        className={clsx('mx-2 fade-in hidden', {'!block': isSelected})}
        onClick={(e) => {
          e.stopPropagation();
          onDeleteRequest(tab);
        }}>
        <RiDeleteBin6Line className="text-red-400" />
      </div>
    </div>
  );
};

export default TabItem;
