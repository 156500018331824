export const adminTabs = [
  {key: 'company', label: 'Company'},
  {key: 'product/service', label: 'Product/Service'},
  {key: 'messages', label: 'Messages'},
  {key: 'comments', label: 'Comments'}
];

export const messageTabs = [
  {key: 'CompanextMsg', label: 'Companext Messages'},
  {key: 'ProductCmt', label: 'Product Comments'}
];

export const staticPages = [
  {value: 'about', label: 'About'},
  {value: 'faq', label: 'FAQ'},
  {value: 'privacy', label: 'Privacy'},
  {value: 'user_agrement', label: 'User Agrement'},
  {value: 'brand_policy', label: 'Brand Policy'}
];
