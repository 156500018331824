import useUser from 'hooks/user/useUser';
import {AxiosError, AxiosRequestConfig} from 'axios';
import {allocateParamToString, urlGenerator} from 'utils';
import {useMutation, useQueryClient} from 'react-query';
import {useAxios, useError} from 'hooks';
import merge from 'lodash/merge';
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import forEach from 'lodash/forEach';
import {mutationRequestProps} from 'types/request';

interface IPostConfig {
  url: string;
  query?: object;
  version?: number;
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';
  removeQueries?: Array<Array<string | number | undefined | null> | string>;
  refetchQueries?: Array<Array<string | number | undefined | null> | string>;
  successMessage?: string;
  form?: any;
  isMultipart?: boolean;
  showError?: boolean;
  onSuccess?(response: any, request?: any, params?: any): void;
  onError?(error: any, request?: any, params?: any): void;
  isUrlencoded?: boolean;
}

/**
 *
 * @param url
 * @param method
 * @param query
 * @param version
 * @param form
 * @param isMultipart
 * @param successMessage  => you should pass a key from translation like "general:messages:success"
 * @param showError
 * @param removeQueries
 * @param refetchQueries
 * @param onSuccess
 * @param onError
 * @param isUrlencoded
 */
const usePost = ({
  url,
  method = 'POST',
  query,
  version,
  form,
  isMultipart,
  successMessage = '',
  showError = true,
  removeQueries,
  refetchQueries,
  onSuccess,
  onError,
  isUrlencoded = false
}: IPostConfig) => {
  const queryClient = useQueryClient();
  const user = useUser();
  const AxiosInstance = useAxios();
  const errorHandler = useError({form, showError});

  const requestConfig: AxiosRequestConfig = {
    headers: {
      Authorization: user?.token ? `Bearer ${user?.token}` : '',
      'Content-type': isMultipart
        ? 'multipart/form-data'
        : isUrlencoded
        ? 'application/x-www-form-urlencoded'
        : 'application/json',
      silent: !showError,
      successMessage
    },

    url: urlGenerator(url, version),
    method,
    params: query
  };

  const createRequest = ({body, queryParams, params, token}: mutationRequestProps) => {
    if (queryParams) set(requestConfig, 'params', merge(query, queryParams));
    if (token) set(requestConfig, ['headers', 'Authorization'], `Bearer ${token}`);
    if (params) set(requestConfig, 'url', allocateParamToString(urlGenerator(url, version), params));
    set(requestConfig, 'data', body);
    return AxiosInstance(requestConfig);
  };

  const mutationData = useMutation(createRequest, {
    retry: false,
    onSuccess: (data, variables) => {
      forEach(removeQueries, (removeQuery: Array<string | number | undefined | null> | string) =>
        queryClient.removeQueries(removeQuery)
      );
      forEach(refetchQueries, (refetchQuery: Array<string | number | undefined | null> | string) =>
        queryClient.refetchQueries(refetchQuery)
      );
      if (isFunction(onSuccess)) {
        onSuccess(data?.data, variables, variables?.params);
      }
    },
    onError: (error: AxiosError, variables) => {
      errorHandler.handle(0, error);
      if (isFunction(onError)) {
        onError(error.request, variables, variables?.params);
      }
    }
  });

  const post = (body?: any, queryParams?: object, params?: object, token?: string) =>
    mutationData.mutate({body, queryParams, params, token});

  const retry = () =>
    mutationData?.mutate({
      body: mutationData?.variables?.body,
      queryParams: mutationData?.variables?.queryParams,
      params: mutationData?.variables?.params,
      token: mutationData?.variables?.token
    });

  return {...mutationData, post, retry, params: mutationData.data?.data?.params};
};

export default usePost;
