import first from 'lodash/first';
import entries from 'lodash/entries';
import map from 'lodash/map';
import split from 'lodash/split';
import keys from 'lodash/keys';
import {AxiosError} from 'axios';
import {ResponseErrorHandler} from 'utils';
import {useMessage} from 'hooks';

interface Props {
  form?: any;
  showError?: boolean;
}

const useError = (props?: Props) => {
  const {showMessage} = useMessage();

  const handle = (failureCount: number, error: AxiosError): boolean => {
    if (props?.showError) {
      const message: string | null = ResponseErrorHandler(error);
      if (message) showMessage({message, type: 'error'});
    }
    if (props?.form && error?.response?.status === 422) {
      try {
        props.form.setFields(
          map(entries(error?.response?.data?.data), ([name, errors]) => ({
            name: split(name, '.'),
            errors
          }))
        );
        props.form.scrollToField(split(first(keys(error?.response?.data?.data)), '.'), {behavior: 'smooth'});
      } catch (e) {
        console.error(e);
      }
    }
    if (error?.response?.status === 404 || error?.response?.status === 500) return false;
    return failureCount <= 1;
  };

  return {handle};
};

export default useError;
