import React from 'react';
import {ProductDataApi} from 'types/product';
import {getDateFromIsoString} from 'utils';

interface Props {
  data?: ProductDataApi;
}

const ProductDescription: React.FC<Props> = ({data}) => {
  return (
    <div className="fade-in ">
      <div className="p-3 w-full flex justify-end">
        <p dir="auto" className=" text-gray-600 text-xs md:text-base">
          {getDateFromIsoString(data?.created_at)}
        </p>
      </div>
      <div>
        <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line my-4">
          {data?.description}
        </p>
      </div>
      <div className="flex mt-8 flex-wrap">
        {data?.tags?.map((item) => (
          <p
            dir="auto"
            key={item?.id?.toString()}
            className="bg-gray-400 font-bold my-2  py-1 px-2 rounded-full mr-2 text-sm">
            {item.name}
          </p>
        ))}
      </div>
    </div>
  );
};
export default ProductDescription;
