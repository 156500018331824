import React, {createContext, Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import type {FilterItem} from 'types/company';

interface FilterCtx {
  filters: FilterItem | undefined;
  setFilters: Dispatch<SetStateAction<FilterItem | undefined>>;
}

const FilterContext = createContext<FilterCtx>(null as any);

export const FilterProvider: FC = ({children}) => {
  const [filters, setFilters] = useState<FilterItem | undefined>(undefined);

  const filtersProviderValue = useMemo(() => ({filters, setFilters}), [filters]);

  return <FilterContext.Provider value={filtersProviderValue}>{children}</FilterContext.Provider>;
};

export default FilterContext;
