import React, {FC, useEffect, useState} from 'react';
import {Button} from 'components';
import {NoConnection} from 'assets';

const Connection: FC = ({children}) => {
  const [offline, setOffline] = useState<boolean>(false);

  useEffect(() => {
    if (!navigator.onLine) setOffline(true);

    window.addEventListener('online', () => {
      setOffline(false);
    });

    window.addEventListener('offline', () => {
      setOffline(true);
    });
  }, []);

  const onRefresh = () => {
    window.location.reload();
  };

  if (offline)
    return (
      <div className="flex flex-col justify-center items-center">
        <NoConnection />
        <h6 className="font-bold text-pen text-xl">No Internet connection</h6>
        <p className="text-pen text-normal my-2">Check your connection, then refresh the page</p>
        <Button success small onClick={onRefresh} className="my-3">
          Refresh
        </Button>
      </div>
    );
  return <>{children}</>;
};

export default Connection;
