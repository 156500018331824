import React, {CSSProperties, FC, MouseEvent, ReactNode} from 'react';
import {Spinner} from 'components';
import clsx from 'clsx';

interface Props {
  children?: any;
  className?: string;
  style?: CSSProperties;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  success?: boolean;
  fill?: boolean;
  link?: boolean;
  spinnerColor?: string;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  small?: boolean;
  icon?: ReactNode;
}

const Button: FC<Props> = ({
  children,
  className,
  success,
  onClick,
  type = 'button',
  spinnerColor = '!bg-white',
  style,
  isLoading,
  link,
  fill = false,
  small = false,
  icon
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      className={clsx(
        'rounded-full whitespace-nowrap cursor-pointer text-center text-md border-2 hover:text-secondary transition-all duration-200 ',
        {'text-sm p-1 md:px-2': small},
        {
          'text-white bg-primary hover:shadow-xl': fill,
          'bg-transparent  hover:border-secondary': !fill,
          'text-primary': success && !fill
        },
        {'border-0 !p-0 text-pen': link},
        {'border-primary': success},
        {'px-4 md:px-4 py-2 md:py-3': !small},
        className
      )}>
      {isLoading ? (
        <Spinner color={spinnerColor} />
      ) : (
        <>
          {icon && icon}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
