import React, {FC} from 'react';
import {useInfinite} from 'hooks';
import {ListFooter, ServerError, Spinner} from 'components';
import {CompanextMessageCard} from 'containers';
import map from 'lodash/map';

interface Props {
  type: 'product' | 'companext';
}

const AdminMessagesTab: FC<Props> = ({type}) => {
  const fetchMessages = useInfinite({
    name: type === 'product' ? ['panel', 'message', 'product'] : ['panel', 'message', 'companext'],
    url: type === 'product' ? 'comments/paginate' : 'companies/companext_messages',
    enabled: true
  });

  if (fetchMessages?.isLoading) return <Spinner center className="mt-16" />;
  if (fetchMessages?.isError) return <ServerError className="mt-16" onTryAgain={fetchMessages?.refetch} />;
  return (
    <div className="fade-in">
      {map(fetchMessages?.data, (message, index: number) => (
        <CompanextMessageCard data={message} key={index} />
      ))}
      <ListFooter
        onFetchMoreClick={fetchMessages?.fetchNextPage}
        canFetchMore={fetchMessages?.hasNextPage}
        isFetchingMore={fetchMessages?.isFetchingNextPage}
      />
    </div>
  );
};

export default AdminMessagesTab;
