import React, {FC} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {Forget, Register, Login, Verify} from 'pages';

const AuthRoutes: FC = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/register`} component={Register} exact />
      <Route path={`${path}/login`} component={Login} />
      <Route path={`${path}/verify`} component={Verify} exact />
      <Route path={`${path}/forget`} component={Forget} exact />
    </Switch>
  );
};

export default AuthRoutes;
