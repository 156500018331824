import React, {FC, ReactNode} from 'react';
import {logoFullImg} from 'assets';
import {Button} from 'components';
import clsx from 'clsx';

interface Props {
  onSubmit?: () => void;
  containerClassName?: string;
  loading?: boolean;
  isModal?: boolean;
  buttonText?: string;
  detailText?: string;
  headerText?: string;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

const AuthLayout: FC<Props> = ({
  onSubmit,
  containerClassName,
  isModal,
  loading,
  buttonText = 'continue',
  headerText,
  detailText,
  children,
  header,
  footer
}) => {
  return (
    <form className="fade-in" onSubmit={onSubmit}>
      <div className={clsx('flex-center flex-col mt-24', {'!mt-4': isModal}, containerClassName)}>
        <img src={logoFullImg} className="w-1/2 md:w-2/5 lg:w-1/4" alt="logo" />
        {header}
        <h6 className="text-gray-600 text-center mt-2">{headerText}</h6>
        {detailText && <p className="text-gray-600 text-center">{detailText}</p>}
        <div className="mt-8 w-full md:w-3/4 lg:w-2/5 m-auto">
          {children}
          <Button fill className="w-full" type="submit" isLoading={loading}>
            {buttonText}
          </Button>
          {footer}
        </div>
      </div>
    </form>
  );
};

export default AuthLayout;
