import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {TextButton, Spinner, TextBox} from 'components';
import {usePost, useUser} from 'hooks';
import {getDateFromIsoString} from 'utils';
import * as yup from 'yup';
import type {IComment, ProductDataApi} from 'types/product';

interface Props {
  comment?: IComment;
  product?: ProductDataApi;
  onSuccess?(): void;
}

const schema = yup.object().shape({
  message: yup.string().required('Please insert reply text')
});

const ProductComment: React.FC<Props> = ({comment, onSuccess, product}) => {
  const user = useUser();
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const commentsDiVRef = useRef(null as any);
  const location = useLocation();

  const {handleSubmit, control, reset} = useForm({
    resolver: yupResolver(schema)
  });

  const postMessage = usePost({
    url: 'comments',
    refetchQueries: [['product', product?.id]],
    onSuccess: () => {
      if (onSuccess) onSuccess();
      reset();
    }
  });

  const onSubmit = (data: any) => {
    postMessage.post({message: data?.message, product_id: product?.id, reply_id: comment?.id});
  };

  useEffect(() => {
    if (location?.hash === `#comments-${comment?.id}`) {
      commentsDiVRef.current.scrollIntoView();
    } else if (comment?.replies?.length) {
      const filterComment = comment.replies.filter((i) => `#comments-${i?.id}` === location?.hash);
      if (filterComment?.length) {
        commentsDiVRef.current.scrollIntoView();
      }
    }
  }, [comment]);

  return (
    <>
      <div className="bg-gray-300 rounded-lg border border-gray-400 px-2 my-4" ref={commentsDiVRef}>
        <div className="flex justify-between py-1">
          <p dir="auto" className="text-gray-700  text-xs p-2">
            {comment?.user?.email}
          </p>
          <p dir="auto" className="text-xs text-gray-600">
            {getDateFromIsoString(comment?.created_at)}
          </p>
        </div>
        <div className="my-2">
          <p dir="auto" className="text-sm">
            {comment?.message}
          </p>
        </div>
        {comment?.replies?.map((reply) => (
          <div
            key={reply?.id?.toString()}
            className="bg-gray-400 rounded-lg border border-gray-500 px-2 my-4 w-full md:w-3/4">
            <div className="flex justify-between py-2">
              <p dir="auto" className="text-gray-700 font-bold text-sm">
                {reply?.user?.email}
              </p>
              <p dir="auto" className="text-xs text-gray-600">
                {getDateFromIsoString(reply?.created_at)}
              </p>
            </div>
            <div className="my-4">
              <p dir="auto" className="text-sm">
                {reply?.message}
              </p>
            </div>
          </div>
        ))}
        {isReplying ? (
          <form className="my-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fade-in flex flex-row w-full ">
              <TextBox
                control={control}
                name="message"
                placeholder={`Write Reply to ${comment?.user?.email}`}
                containerClassName="w-full"
                className="w-full"
              />
              {postMessage?.isLoading ? (
                <Spinner />
              ) : (
                <button type="submit" className="fade-in ml-2">
                  Send
                </button>
              )}
            </div>
          </form>
        ) : (
          <div className=" p-4 flex justify-end ">
            {user.isLogin && <TextButton onClick={() => setIsReplying(true)}>Reply</TextButton>}
          </div>
        )}
      </div>
    </>
  );
};
export default ProductComment;
