import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {Button, Modal, TextButton} from 'components';
import {usePost} from 'hooks';
import {allocateParamToString} from 'utils';
import isEmpty from 'lodash/isEmpty';

interface refProps {
  open: (params: object) => void;
  close: () => void;
}

interface Props {
  ref: RefObject<refProps>;
  url: string;
  title: string;
  refetchQueries?: Array<Array<string | number | undefined | null> | string>;
  onSuccess?(): void;
}

const DeletePrompt: ForwardRefRenderFunction<refProps, Props> = (
  {url, title, refetchQueries, onSuccess},
  forwardedRef: ForwardedRef<refProps>
) => {
  const [params, setParams] = useState<object | undefined>(undefined);

  useImperativeHandle(forwardedRef, () => ({
    open(params: object) {
      setParams(params);
    },
    close() {
      setParams(undefined);
    }
  }));

  const onClose = useCallback(() => setParams(undefined), []);

  const postDeleteItem = usePost({
    url,
    method: 'DELETE',
    refetchQueries,
    onSuccess: () => {
      onClose();
      if (onSuccess) onSuccess();
    }
  });

  const onDelete = () => {
    postDeleteItem.post({}, {}, params);
  };

  return (
    <Modal isOpen={!isEmpty(params)} onClose={onClose}>
      <div className="flex flex-col justify-center items-center">
        <AiOutlineDelete className="text-red-500" size={25} />
        <p className="text-base px-2 my-4">{allocateParamToString(title, params)}</p>
        <div className="flex">
          <TextButton
            className="text-red-500"
            spinnerColor="!bg-red-500"
            onClick={onDelete}
            isLoading={postDeleteItem?.isLoading}>
            Yes, Delete
          </TextButton>
          <Button onClick={onClose} className="mx-4" success>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(DeletePrompt);
