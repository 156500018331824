import React from 'react';
import {FaUser} from 'react-icons/fa';

const ProfileAvatar: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div className="border rounded-full border-gray-700 ml-8 p-2 cursor-pointer" {...props}>
      <FaUser size={20} />
    </div>
  );
};

export default ProfileAvatar;
