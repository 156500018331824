import React, {FC} from 'react';
import {HiHome, HiViewGrid} from 'react-icons/hi';
import {BsBookmarkFill, BsFillChatDotsFill} from 'react-icons/bs';
import {MenuIcon} from 'components';
import {useLocation} from 'react-router-dom';

interface Props {
  isLoggedIn: boolean;
}

const UserMenuTop: FC<Props> = ({isLoggedIn}) => {
  const pathName = useLocation().pathname;

  return (
    <div className="flex items-center w-full fixed bg-white bottom-0 right-0 border-t md:border-0 border-gray-400 h-16 lg:static lg:w-2/5 z-[40]">
      <MenuIcon title="Home" iconRender={<HiHome />} href="/" isActive={pathName === '/'} />
      <MenuIcon
        title="My Company"
        iconRender={<HiViewGrid />}
        isActive={pathName === '/panel/company'}
        href={isLoggedIn ? '/panel/company' : '/auth/login'}
      />
      <MenuIcon
        title="Vendor List"
        iconRender={<BsBookmarkFill />}
        isActive={pathName === '/panel/vendor'}
        href={isLoggedIn ? '/panel/vendor' : '/auth/login'}
      />
      <MenuIcon
        title="Messaging"
        iconRender={<BsFillChatDotsFill />}
        isActive={pathName === '/panel/message'}
        href={isLoggedIn ? '/panel/message' : '/auth/login'}
      />
    </div>
  );
};
export default UserMenuTop;
