import React, {FC} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {
  Vendor,
  Password,
  Setting,
  Message,
  Admin,
  MutateStaticPage,
  SendAllMessage,
  MutateProduct,
  MyCompany,
  CreateCompany
} from 'pages';
import {useUser} from 'hooks';

const PanelRoutes: FC = () => {
  const {path} = useRouteMatch();
  const user = useUser();

  return (
    <Switch>
      <Route path={`${path}/password`} component={Password} exact />
      <Route path={`${path}/vendor`} component={Vendor} exact />
      <Route path={`${path}/setting`} component={Setting} exact />
      <Route path={`${path}/message/send`} component={SendAllMessage} exact />
      <Route path={`${path}/message`} component={Message} />
      <Route path={`${path}/admin`} component={Admin} exact />
      <Route path={`${path}/admin/footer`} component={MutateStaticPage} exact />
      <Route path={`${path}/product/new`} exact>
        <MutateProduct isNew />
      </Route>
      <Route path={`${path}/product/edit/:id`} component={MutateProduct} exact />
      <Route path={`${path}/product/:id`} component={MutateProduct} exact />
      <Route path={`${path}/company`} component={user?.role?.id === 2 ? MyCompany : CreateCompany} exact />
    </Switch>
  );
};

export default PanelRoutes;
