import React, {ElementRef, FC, useCallback, useRef, useState} from 'react';
import {useFetch} from 'hooks';
import {DynamicTabs, FetchContainer} from 'components';
import {BookmarkItemsList, NewBookmarkModal} from 'containers';
import get from 'lodash/get';

const BookmarkTabs: FC = () => {
  const newBookmarkRef = useRef<ElementRef<typeof NewBookmarkModal>>(null);

  const fetchBookmarks = useFetch({
    name: 'bookmarks',
    url: 'bookmarks',
    enabled: true
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const openNewModal = useCallback(() => {
    if (newBookmarkRef?.current?.open) newBookmarkRef.current.open();
  }, []);

  return (
    <FetchContainer
      isLoading={fetchBookmarks?.isFetching}
      isError={fetchBookmarks?.isError}
      onReFetch={fetchBookmarks?.refetch}>
      <div className="w-full mt-16">
        <DynamicTabs
          tabs={fetchBookmarks?.data?.data}
          selectedIndex={selectedTabIndex}
          onNewTab={openNewModal}
          onTabIndexChange={setSelectedTabIndex}
        />
        <BookmarkItemsList tabID={get(fetchBookmarks?.data?.data, [selectedTabIndex, 'id'])} />
        <NewBookmarkModal ref={newBookmarkRef} />
      </div>
    </FetchContainer>
  );
};
export default BookmarkTabs;
