import {Button, OutSideCloserDiv} from 'components';
import React, {useState} from 'react';
import {MessageModalInfo, DeleteItemModalInfo} from 'types/modalInfo';
import {ProductItem} from 'types/superAdmin';
import {getDateFromIsoString} from 'utils';

interface Props {
  data: ProductItem;
  onModalOpen?(id?: number): void;
  opendModalId?: number;
  onSendMessageRequested(info: MessageModalInfo): void;
  onDeleteRequested(info: DeleteItemModalInfo): void;
}

const CompanyCard: React.FC<Props> = ({
  data,
  opendModalId,
  onModalOpen = () => {},
  onSendMessageRequested,
  onDeleteRequested
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <div className="flex my-4 relative">
      <div className="w-1/6 flex items-center justify-center">
        <img className="w-24 h-24  rounded-full border" alt="product" src={data?.avatar} />
      </div>
      <div className="w-5/6 flex border-b border-gray-300">
        <div className="w-5/6">
          <p className="fotnt-bold text-indigo-700 text-lg">{data?.name}</p>
          <p className="mt-4 text-gray-600 ">{data?.company_name}</p>
        </div>
        <div className="w-1/6 flex flex-col  items-center justify-center">
          <p className="text-sm text-gray-600 py-4">{getDateFromIsoString(data?.created_at)}</p>
          <Button
            small
            className="w-full"
            onClick={() => {
              setIsEditModalOpen(true);
              onModalOpen(data?.id);
            }}>
            Menu
          </Button>
        </div>
      </div>
      {isEditModalOpen && opendModalId === data?.id && (
        <OutSideCloserDiv
          isOpen={isEditModalOpen}
          onCloseRequested={() => setIsEditModalOpen(false)}
          className="absolute fade-in-down border bg-white shadow-xl rounded-md flex flex-col p-2"
          style={{top: 40, right: -200}}
          noClickId={['edit-product-my-company-delete']}
          id="edit-product-my-company">
          <div
            id="edit-product-my-company-delete"
            className="text-red-500 text-sm cursor-pointer "
            onClick={() => {
              setIsEditModalOpen(false);
              onDeleteRequested({
                id: data.id,
                url: 'products',
                hasComment: true
              });
            }}>
            Delete With Comment
          </div>
          <div
            id="edit-product-my-company-delete"
            className="text-red-500 text-sm cursor-pointer my-4"
            onClick={() => {
              setIsEditModalOpen(false);
              onDeleteRequested({
                id: data.id,
                url: 'products',
                hasComment: false
              });
            }}>
            Delete Without Comment
          </div>
          <div
            id="edit-product-my-company-delete"
            className="text-sm cursor-pointer"
            onClick={() => {
              onSendMessageRequested({id: data.id, url: 'products', hasComment: false});
              setIsEditModalOpen(false);
            }}>
            Send Message
          </div>
        </OutSideCloserDiv>
      )}
    </div>
  );
};

export default CompanyCard;
