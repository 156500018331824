import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  href: string;
  iconRender: any;
  title: string;
  isActive?: boolean;
}

const MenuIcon: React.FC<Props> = ({iconRender, href, title, isActive}) => {
  return (
    <div className="flex-center flex-col cursor-pointer flex-1">
      <Link
        className={`text-xs text-center  ${isActive ? 'text-gray-800' : 'text-gray-400'} flex-center flex-col`}
        to={href}>
        {React.cloneElement(iconRender, {
          size: 20,
          className: isActive ? 'text-gray-800' : 'text-gray-400'
        })}
        {title}
      </Link>
    </div>
  );
};

export default MenuIcon;
