import React, {useCallback, useState} from 'react';
import {ProductListItem} from 'types/products';
import {Link} from 'react-router-dom';
import {EditIconButton, Spinner, ServerError, OutsideView} from 'components';
import {MdMoreVert} from 'react-icons/md';
import {usePost} from 'hooks';
import type {CompanyDataApi} from 'types/company';
import {defaultCompanyImg} from 'assets';
import range from 'lodash/range';
import toString from 'lodash/toString';

interface Props {
  product: ProductListItem;
  totalProductLength: number;
  onDelete?(product: ProductListItem): void;
  company?: CompanyDataApi;
  isAdminView?: boolean;
}

const EditableCompanyCard: React.FC<Props> = ({
  product,
  onDelete = () => {},
  company,
  totalProductLength,
  isAdminView
}) => {
  const postProductPriority = usePost({
    url: 'products/{id}/change_priority',
    method: 'GET'
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const close = useCallback(() => setIsEditModalOpen(false), []);

  if (postProductPriority?.isLoading) return <Spinner center />;
  if (postProductPriority?.isError) return <ServerError className="mt-16" onTryAgain={postProductPriority?.retry} />;

  return (
    <>
      <div className="my-2 flex">
        <Link
          to={`/${product ? 'product' : 'company'}/${product?.id}`}
          className="w-2/12 flex justify-start items-start lg:pl-4 cursor-pointer">
          <img
            src={product?.avatar}
            className="rounded-full border border-gray-600 w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20"
            alt="avatar"
            onError={(e) => {
              e.currentTarget.src = defaultCompanyImg;
            }}
          />
        </Link>
        <div className="w-10/12 md:ml-0 pb-4 border-b border-gray-400 flex-1 flex    ">
          <Link to={`/${product ? 'product' : 'company'}/${product?.id}`} className="flex-7 cursor-pointer w-8/12">
            <p className="text-secondary line-clamp-1 text-sml md:text-lg font-bold text-left " dir="auto">
              {product?.name}
            </p>
            <p className="text-secondary line-clamp-1 text-sml text-left" dir="auto">
              {product?.description}
            </p>
          </Link>
          <div className="flex-2 flex flex-col  items-center relative">
            {isAdminView && (
              <>
                <Link
                  to={{
                    pathname: `/panel/product/edit/${product?.id}`,
                    state: {company}
                  }}>
                  <EditIconButton />
                </Link>
                <button
                  type="button"
                  className="p-2 cursor-pointer "
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}>
                  <MdMoreVert size={24} className="text-gray-800" />
                </button>
              </>
            )}
            {isEditModalOpen && (
              <OutsideView onOutsideClick={close}>
                <div
                  className="absolute  w-56  items-start fade-in-down border bg-white shadow-xl rounded-md flex  flex-col p-2"
                  style={{
                    top: 0,
                    right: window.innerWidth < 768 ? 86 : -170
                  }}
                  id="edit-product-my-company">
                  <div
                    id="edit-product-my-company-delete"
                    className="text-red-500 text-sm cursor-pointer my-2 "
                    onClick={() => onDelete(product)}>
                    Delete
                  </div>
                  <div id="edit-product-my-company-move" className="flex text-sm">
                    Move To Position
                    <select
                      className="mx-1"
                      onChange={(e) => {
                        postProductPriority.post({}, {from: product?.priority, to: e.target.value}, {id: product?.id});
                        setIsEditModalOpen(false);
                      }}>
                      {range(1, totalProductLength + 1).map((priority) => (
                        <option
                          key={priority}
                          value={toString(priority)}
                          selected={product?.priority === toString(priority)}>
                          {priority}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </OutsideView>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditableCompanyCard;
