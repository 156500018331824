import React from 'react';
import {Spinner, TextBox, Button, TextEditor, FetchContainer} from 'components';
import {useFetch, usePost} from 'hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface Props {
  page: string;
}

const schema = yup.object().shape({
  title: yup.string().required('Please insert title'),
  body: yup.string().required('Please insert body')
});

const FooterMutate: React.FC<Props> = ({page}) => {
  const fetchStatic = useFetch({
    name: ['static', page],
    url: '/statics/{page}',
    params: {page},
    enabled: true
  });

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(schema)
  });

  const postStaticPage = usePost({
    url: '/static-pages'
  });

  const onSubmit = (data: any) => {
    postStaticPage.post({key: page, title: data.title, text: data.body});
  };

  return (
    <FetchContainer onReFetch={fetchStatic?.refetch} isLoading={fetchStatic?.isFetching} isError={fetchStatic?.isError}>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <TextBox name="title" control={control} placeholder="Page Title" defaultValue={fetchStatic?.data?.title} />
        <TextEditor name="body" control={control} defaultValue={fetchStatic?.data?.text} />
        <div className="mt-4 flex justify-end">
          <Button small fill type="submit" isLoading={postStaticPage?.isLoading}>
            Save
          </Button>
        </div>
      </form>
    </FetchContainer>
  );
};

export default FooterMutate;
