import React, {FC} from 'react';
import {CompanyDataApi} from 'types/company';

interface Props {
  data?: CompanyDataApi;
}

const CompanyHeader: FC<Props> = ({data}) => {
  return (
    <div className="fade-in ">
      <div className="bg-primary w-100 h-28" />
      <div className="md:flex">
        <div className="  flex items-center justify-center md:justify-start md:pl-6 relative ">
          <div>
            <img
              src={data?.avatar}
              alt="company"
              className="w-24 h-24 md:w-32 md:h-32 rounded-lg bg-white border object-cover -mt-12"
            />
          </div>
        </div>
        <div className=" py-4 md:pl-4">
          <p dir="auto" className="text-base md:text-xl font-bold text-center md:text-left">
            {data?.name}
          </p>
          <p dir="auto" className="text-sm text-gray-600 text-center md:text-left px-12 md:px-0">
            {data?.tagline}
          </p>
        </div>
      </div>
    </div>
  );
};
export default CompanyHeader;
