import React, {ChangeEvent, FC, FormEvent, useCallback} from 'react';
import {Control, useController} from 'react-hook-form';
import clsx from 'clsx';
import {usePost} from 'hooks';
import debounce from 'lodash/debounce';
import {Spinner} from 'components';
import {allocateParamToString} from 'utils';

interface props {
  url: string;
  method: 'POST' | 'GET';
  name: string;
  placeholder?: string;
  defaultValue?: string | number | string[];
  className?: string;
  onError?(error: any, request?: any, params?: any): void;
  onSuccess?(response: any, request?: any, params?: any): void;
  control: Control<any>;
  trigger: any;
  containerClassName?: string;
  error?: string;
  errorMessage: string;
  successMessage: string;
  onChange?(e: FormEvent<HTMLInputElement>): void;
}

const TextBox: FC<props> = ({
  name,
  url,
  method,
  defaultValue,
  control,
  errorMessage,
  successMessage,
  trigger,
  placeholder,
  onError,
  onSuccess,
  className,
  containerClassName
}) => {
  const {
    field,
    fieldState: {error}
  } = useController({name, control, defaultValue});

  const postInputCheck = usePost({
    url,
    method,
    onError,
    onSuccess(response: any, request?: any, params?: any) {
      field.onChange(request?.body?.link);
      if (onSuccess && response) onSuccess(response, request, params);
      else if (onError) onError(allocateParamToString(errorMessage, request?.body));
    }
  });

  const debounceSearchLink = debounce((link: string) => {
    postInputCheck.post({link}, {link});
  }, 2000);

  const onChangeLink = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    const isValid: boolean = await trigger(name);
    if (isValid) {
      if (onError) onError('not checked link');
      debounceSearchLink(e.target.value);
    }
  }, []);

  return (
    <div className={containerClassName}>
      <div className="flex flex-row justify-between">
        <input
          {...field}
          placeholder={placeholder}
          className={clsx(
            'border rounded-full w-full h-8 px-4 py-4 text-sm focus:border-primary transition duration-200',
            {'border-red-500': error, 'border-gray-400': !error},
            className
          )}
          onChange={onChangeLink}
        />
        {postInputCheck?.isLoading && (
          <div className="ml-2 w-20">
            <Spinner />
          </div>
        )}
      </div>
      {postInputCheck?.data?.data && !error ? (
        <p className="text-sm self-start text-green-500">
          {allocateParamToString(successMessage, postInputCheck?.variables?.body)}
        </p>
      ) : (
        error?.message && <p className="text-red-500 text-sm mx-4">{error?.message}</p>
      )}
    </div>
  );
};

export default TextBox;
