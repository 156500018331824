import React, {ReactElement, useState, useEffect, FC} from 'react';
import map from 'lodash/map';

interface Props {
  activeIndex: number | string;
  children: ReactElement[];
}

const TabContainer: FC<Props> = ({children, activeIndex = 0}) => {
  const [mounted, setMounted] = useState([activeIndex]);

  useEffect(() => {
    if (!mounted.includes(+activeIndex)) setMounted((prv) => [...prv, +activeIndex]);
  }, [activeIndex, mounted]);

  return (
    <div>
      {map(children, (item, index) => {
        if (mounted.includes(index))
          return (
            <div className={+activeIndex === index ? 'block' : 'hidden'} key={index}>
              {item}
            </div>
          );
        return null;
      })}
    </div>
  );
};

export default TabContainer;
