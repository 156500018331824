import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import map from 'lodash/map';
import {footerMenu} from 'assets';

interface Props {
  className?: string;
}

const Footer: FC<Props> = ({className}) => {
  return (
    <footer className={clsx('py-4 bg-slate-300 w-100 flex flex-wrap items-center px-8 mb-16 lg:mb-0', className)}>
      {map(footerMenu, (menu, index: number) => (
        <Link key={index} to={menu?.link} className="mx-4 text-sm lg:text-md text-gray-900 my-2 md:my-0 lg:my-0">
          {menu?.title}
        </Link>
      ))}
      {/* <FooterLink to="/static/blog" title="Blog" /> */}
    </footer>
  );
};

export default Footer;
