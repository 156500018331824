//images
export const logoMiniImg = require('./images/logo_mini.jpg');
export const logoFullImg = require('./images/logo_full.png');
export const defaultCompanyImg = require('./images/defaultCompanyImg.jpg');

//constants
export * from './constants/admin';
export * from './constants/company';
export * from './constants/home';

//svgs
export {default as NoConnection} from './svgs/NoConnection';
