import React, {FC, ReactNode} from 'react';
import {Header, Footer} from 'containers';
import {MessageModal} from 'components';

interface Props {
  children?: ReactNode;
}

const PageContainer: FC<Props> = ({children}) => {
  return (
    <>
      <Header />
      <section className="container min-h-screen">{children}</section>
      <MessageModal />
      <Footer className="mt-24" />
    </>
  );
};
export default PageContainer;
