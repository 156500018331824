import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  ReactElement,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {GrFormClose} from 'react-icons/gr';
import clsx from 'clsx';
import {Button} from 'components';

interface refProps {
  open: () => void;
  close: () => void;
}

interface Props {
  ref?: RefObject<refProps>;
  containerClassName?: string;
  modalClassName?: string;
  children: ReactElement;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
}

const Modal: ForwardRefRenderFunction<refProps, Props> = (
  {isOpen, containerClassName, modalClassName, title, onClose, children},
  forwardedRef: ForwardedRef<refProps>
) => {
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(forwardedRef, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const closeModal = useCallback(() => {
    setOpen(false);
    if (onClose) onClose();
  }, []);

  return (
    <Transition as={Fragment} show={isOpen || open} appear>
      <Dialog open={isOpen || open} onClose={closeModal} className="fixed inset-0 z-40 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          </Transition.Child>
          <Transition.Child
            as="div"
            className={clsx('z-50 w-3/4 md:w-2/4 lg:w-2/4 xl:w-2/4 max-h-[90vh]', modalClassName)}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className={clsx('bg-white relative box-shadow rounded-lg p-8 overflow-y-auto', containerClassName)}>
              {title ? (
                <Dialog.Title>
                  <div className="shadow-bottom fixed top-0 left-0 right-0 flex h-14 items-center justify-between border-b bg-body px-4 md:static md:px-0 md:shadow-none">
                    <strong className=" py-3 text-right text-xl font-bold">{title}</strong>
                    <GrFormClose size={26} role="button" onClick={closeModal} />
                  </div>
                </Dialog.Title>
              ) : (
                <Button className="border-none outline-none absolute top-2 right-3" onClick={closeModal}>
                  X
                </Button>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default forwardRef(Modal);
