import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  MouseEvent,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {Button, OutsideView} from 'components';
import {useFetch, usePost} from 'hooks';
import {RiDeleteBin6Line} from 'react-icons/ri';
import filter from 'lodash/filter';
import map from 'lodash/map';

interface refProps {
  open: () => void;
  close: () => void;
}

interface Props {
  ref: RefObject<refProps>;
  tabID: number;
  bookmark?: any;
}

const BookmarkMoveModal: ForwardRefRenderFunction<refProps, Props> = (
  {bookmark, tabID},
  forwardedRef: ForwardedRef<refProps>
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(forwardedRef, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    }
  }));

  const fetchBookmarks = useFetch({
    name: 'bookmarks',
    url: 'bookmarks',
    enabled: isOpen
  });

  const postMoveBookmark = usePost({
    url: 'saved_items/move_to',
    refetchQueries: ['savedItems']
  });

  const DeleteBookmark = usePost({
    url: 'saved_items',
    refetchQueries: ['savedItems']
  });

  const deleteBookmark = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    DeleteBookmark.post({
      action: 'remove',
      reference_id: Number(bookmark?.reference_id),
      reference_type: bookmark?.company_name ? 'product' : 'company'
    });
  };

  const closeModal = useCallback(() => setIsOpen(false), []);

  if (!isOpen) return null;

  return (
    <OutsideView onOutsideClick={closeModal}>
      <div className="absolute w-40 z-99 right-[80px] rounded-md rounded-tl-none max-h-[150px] custom__scroll shadow-md rounded-xl bg-white border-gray-400 border fade-in-down">
        <div className="cursor-pointer m-2 flex bg-red-200 text-red-600 items-center" onClick={deleteBookmark}>
          <RiDeleteBin6Line className="mr-1" />
          <p className="font-bold">Remove</p>
        </div>
        {map(
          filter(fetchBookmarks?.data?.data, (item: any) => item.id !== tabID),
          (bookmarkItem: any) => (
            <Button
              key={bookmarkItem?.id}
              link
              className="!px-2 !pb-1 w-full !text-left"
              spinnerColor="!bg-primary"
              isLoading={postMoveBookmark?.isLoading}
              onClick={(event) => {
                event?.stopPropagation();
                postMoveBookmark.post({bookmark_id: bookmarkItem?.id, id: bookmark?.id});
              }}>
              {bookmarkItem?.name}
            </Button>
          )
        )}
      </div>
    </OutsideView>
  );
};

export default forwardRef(BookmarkMoveModal);
