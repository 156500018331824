import React, {FC} from 'react';
import Select from 'react-select';
import {Control, Controller} from 'react-hook-form';
import {useInfinite} from 'hooks';
import map from 'lodash/map';
import type {SelectBoxItem, SelectBoxOption} from 'types/common';

interface props {
  name: string;
  title?: string;
  url?: string;
  urlName?: Array<string | number | undefined | null> | string;
  options?: SelectBoxOption[];
  query?: object;
  params?: object;
  control?: Control<any>;
  defaultValue?: SelectBoxItem[];
  onChange?(value: any, actionMeta: any): void;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  mode?: 'single' | 'multiple';
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? '#1eac86' : '#000',
    background: '#fff'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    padding: '0 20px',
    background: '#eeeeee',
    borderRadius: '900px',
    width: '100%'
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {...provided, opacity, transition};
  },
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%'
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#000'
  })
};

const SelectBox: FC<props> = ({
  name,
  title,
  control,
  url,
  urlName,
  options,
  params,
  onChange,
  query,
  defaultValue,
  className,
  placeholder,
  isClearable,
  mode = 'single',
  isSearchable
}) => {
  const fetchSelectItems = useInfinite({
    name: urlName,
    url: url!,
    params,
    query
  });

  if (control)
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={map(defaultValue, (item: SelectBoxItem) => ({value: item.id, label: item.name})) || []}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <div className="w-full">
            {title && <p className="text-sm text-gray-400 mb-4 w-full px-4">{title}</p>}
            <Select
              options={
                url
                  ? map(fetchSelectItems?.data, (item: SelectBoxItem) => ({value: item.id, label: item.name}))
                  : options
              }
              styles={customStyles}
              placeholder={placeholder}
              onMenuOpen={url ? fetchSelectItems.refetch : undefined}
              closeMenuOnSelect={mode !== 'multiple'}
              isSearchable={isSearchable || false}
              value={value}
              className={className}
              isMulti={mode === 'multiple'}
              name={name}
              onChange={onChange}
              isLoading={fetchSelectItems?.isFetching && !fetchSelectItems?.isFetchingNextPage}
              isClearable={isClearable}
            />
            {error?.message && <p className="text-red-500 text-sm mx-4">{error?.message}</p>}
          </div>
        )}
      />
    );

  return (
    <div className="w-full">
      {title && <p className="text-sm text-gray-400 mb-4 w-full px-4">{title}</p>}
      <Select
        options={
          url ? map(fetchSelectItems?.data, (item: SelectBoxItem) => ({value: item.id, label: item.name})) : options
        }
        styles={customStyles}
        placeholder={placeholder}
        onMenuOpen={url ? fetchSelectItems.refetch : undefined}
        closeMenuOnSelect={mode !== 'multiple'}
        isSearchable={isSearchable || false}
        className={className}
        isMulti={mode === 'multiple'}
        name={name}
        onChange={onChange}
        isLoading={fetchSelectItems?.isFetching && !fetchSelectItems?.isFetchingNextPage}
        isClearable={isClearable}
      />
    </div>
  );
};

export default SelectBox;
