export const homeTabs = [
  {key: 'company', label: 'Company'},
  {key: 'products', label: 'Products/Service'}
];

export const footerMenu = [
  {
    title: 'About',
    link: '/static/about'
  },
  {
    title: 'FAQ',
    link: '/static/faq'
  },
  {
    title: 'Privacy',
    link: '/static/Privacy'
  },
  {
    title: 'User Agreement',
    link: '/static/user_agrement'
  },
  {
    title: 'Brand Policy',
    link: '/static/brand_policy'
  }
];
