import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {Modal, TextBox, Button} from 'components';
import {usePost} from 'hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface refProps {
  open: () => void;
  close: () => void;
}

interface Props {
  ref: RefObject<refProps>;
  onClose?(): void;
}

const schema = yup.object().shape({
  name: yup.string().required('bookmark Name is Required')
});

const NewBookmarkModal: ForwardRefRenderFunction<refProps, Props> = (
  {onClose},
  forwardedRef: ForwardedRef<refProps>
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(forwardedRef, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    }
  }));

  const {control, handleSubmit} = useForm<{name: string}>({
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });

  const postCreateBookmark = usePost({
    url: '/bookmarks',
    refetchQueries: ['bookmarks']
  });

  const onSubmit = (data: {name: string}) => {
    postCreateBookmark.post(data);
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (onClose) onClose();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form className="flex flex-center flex-col" onSubmit={handleSubmit(onSubmit)}>
        <p className="text-sm text-gray-600">Save New Bookmark</p>
        <div className="flex items-center flex-col">
          <TextBox name="name" control={control} className="my-4 px-6 py-4" placeholder="Bookmark Name" />
          <Button type="submit" isLoading={postCreateBookmark?.isLoading} fill className="ml-4 px-2">
            &nbsp;&nbsp;Save&nbsp;&nbsp;
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default forwardRef(NewBookmarkModal);
