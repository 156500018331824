import React, {FC} from 'react';
import {Modal} from 'components';
import {useMessage} from 'hooks';

const MessageModal: FC = () => {
  const {message, closeMessage, hasMessage} = useMessage();

  return (
    <Modal isOpen={hasMessage} onClose={closeMessage}>
      <div>
        <div className="border-b-2 border-grayLight p-4">Error!</div>
        <div className="p-4">{message?.message}</div>
        <button
          type="button"
          className=" t-0 mt-4 bg-primary rounded-sm px-6 py-2 text-white cursor-pointer flex-center shadow-md text-sm"
          onClick={closeMessage}
          style={{top: '45%', right: '45%'}}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default MessageModal;
