import React, {useState, memo, useCallback, FC} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {TabHeader, TabContainer} from 'components';
import {AboutTab, ProductsTab} from 'containers';
import {EditableTabs} from 'assets';
import type {CompanyDataApi} from 'types/company';
import {hashToArray} from 'utils';
import compact from 'lodash/compact';
import first from 'lodash/first';

interface Props {
  data?: CompanyDataApi;
}

const CompanyTabs: FC<Props> = ({data}) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(first(compact(hashToArray(location.hash))) || 0);

  const onChangeTab = useCallback((i) => {
    setActiveTab(i);
    history.push(`${location.pathname}#${i}`);
  }, []);

  return (
    <div>
      <TabHeader
        activeIndex={activeTab}
        onIndexChange={onChangeTab}
        style={{marginTop: 30}}
        items={EditableTabs}
        productCount={data?.products_count}
      />
      <TabContainer activeIndex={activeTab}>
        <AboutTab data={data} />
        <ProductsTab data={data} />
      </TabContainer>
    </div>
  );
};
export default memo(CompanyTabs);
