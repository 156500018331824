import {lazyWithRetry} from 'utils';

//home
export const Home = lazyWithRetry(() => import('pages/home'));
export const Company = lazyWithRetry(() => import('pages/home/Company'));
export const Product = lazyWithRetry(() => import('pages/home/Product'));
export const Static = lazyWithRetry(() => import('pages/home/Static'));
export const NotFound = lazyWithRetry(() => import('pages/home/NotFound'));

//auth
export const Register = lazyWithRetry(() => import('pages/auth/Register'));
export const Login = lazyWithRetry(() => import('pages/auth/Login'));
export const Verify = lazyWithRetry(() => import('pages/auth/Verify'));
export const Forget = lazyWithRetry(() => import('pages/auth/Forget'));

//panel
export const Password = lazyWithRetry(() => import('pages/panel/SetPassword'));
export const Vendor = lazyWithRetry(() => import('pages/panel/VendorList'));
export const Setting = lazyWithRetry(() => import('pages/panel/Settings'));
export const Message = lazyWithRetry(() => import('pages/panel/Messaging'));
export const Admin = lazyWithRetry(() => import('pages/panel/Admin'));
export const SendAllMessage = lazyWithRetry(() => import('pages/panel/SendAllMessage'));
export const MutateStaticPage = lazyWithRetry(() => import('pages/panel/MutateStaticPage'));
export const MutateProduct = lazyWithRetry(() => import('pages/panel/EditProduct'));
export const CreateCompany = lazyWithRetry(() => import('pages/panel/CreateCompany'));
export const MyCompany = lazyWithRetry(() => import('pages/panel/MyCompany'));
