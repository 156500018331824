import {useContext} from 'react';
import {UserContext} from 'contexts';
import {useQueryClient} from 'react-query';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';

function useUser() {
  const {user, setUser} = useContext(UserContext);
  const queryClient = useQueryClient();

  const getUser = () => {
    const profile: any = queryClient.getQueryData('profile');
    return get(profile, ['data', 'user']);
  };

  const getProfile = () => {
    const profile: any = queryClient.getQueryData('profile');
    return get(profile, ['data']);
  };

  const getId = () => {
    const profile: any = queryClient.getQueryData('profile');
    return get(profile, ['data', 'user', 'id']);
  };

  const isMySelf = (id?: number | string) => {
    const profile: any = queryClient.getQueryData('profile');
    return get(profile, ['data', 'user', 'id']) === toNumber(id);
  };

  return {
    ...user,
    setUser,
    getUser,
    getProfile,
    isMySelf,
    getId
  };
}
export default useUser;
