import {useContext} from 'react';
import {MessageContext} from 'contexts';
import type {MessageModal} from 'types/common';
import isEmpty from 'lodash/isEmpty';

function useMessage() {
  const {message, setMessage} = useContext(MessageContext);

  const showMessage = (newMessage: MessageModal) => {
    setMessage(newMessage);
  };

  const closeMessage = () => {
    setMessage(null);
  };

  const hasMessage: boolean = !isEmpty(message);

  return {
    message,
    showMessage,
    hasMessage,
    closeMessage
  };
}

export default useMessage;
