import React, {CSSProperties} from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  center?: boolean;
  color?: string;
  style?: CSSProperties;
}

const Spinner: React.FC<Props> = ({center, className, color, style}) => {
  return (
    <div className={clsx('spinner', {'flex justify-center items-center': center}, className)}>
      <div className={clsx('bounce1', color)} style={style} />
      <div className={clsx('bounce2', color)} style={style} />
      <div className={clsx('bounce3', color)} style={style} />
    </div>
  );
};

export default Spinner;
