import React, {FC, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {IoMdAdd} from 'react-icons/io';
import {Control, Controller} from 'react-hook-form';
import cloneWith from 'lodash/cloneWith';
import pullAt from 'lodash/pullAt';
import map from 'lodash/map';

interface Props {
  name: string;
  control: Control<any>;
  defaultValue?: string | number | string[];
}

const TagsEdit: FC<Props> = ({name, control, defaultValue}) => {
  const [isAdding, setIsAdding] = useState<boolean>(true);
  const [text, setText] = useState<string>('');

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <div>
          <div className="flex flex-row flex-wrap">
            {map(value, (tag: any, index: number) => (
              <button
                type="button"
                onClick={() =>
                  onChange(
                    cloneWith(value, (oldValue) => {
                      pullAt(oldValue, index);
                      return oldValue;
                    })
                  )
                }
                className="bg-gray-200 rounded-full py-2 px-3 m-1 flex flex-row items-center">
                <AiOutlineClose />
                {tag}
              </button>
            ))}
            {!isAdding && (
              <button type="button" className="mx-4 fade-in" onClick={() => setIsAdding(true)}>
                <IoMdAdd className="text-blue-400 " size={25} />
              </button>
            )}
            {isAdding && (
              <form className="flex flex-row items-center fade-in">
                <input
                  placeholder="Write Tag Name ..."
                  onChange={(_e) => setText(_e.target.value)}
                  className="border rounded-full w-full h-8 px-4 py-4 text-sm focus:border-primary transition duration-200"
                />
                <button
                  style={{
                    marginLeft: '-42px',
                    height: '32px',
                    width: '42px'
                  }}
                  type="submit"
                  className="bg-primary text-white rounded-r-full"
                  onClick={() => {
                    onChange([...value, text]);
                    setIsAdding(false);
                    setText('');
                  }}>
                  Add
                </button>
              </form>
            )}
          </div>
          {error?.message && <p className="text-red-500 text-sm mx-4 ">{error?.message}</p>}
        </div>
      )}
    />
  );
};

export default TagsEdit;
