import {useContext} from 'react';
import {UserContext} from 'contexts';
import {useQueryClient} from 'react-query';
import {usePost} from 'hooks';
import {useHistory} from 'react-router-dom';

function useLogOut() {
  const {setUser} = useContext(UserContext);
  const queryClient = useQueryClient();
  const history = useHistory();

  const logOutRequest = usePost({
    url: 'logout',
    method: 'GET',
    onSuccess: () => {
      setUser({isLogin: false});
      queryClient.removeQueries();
      history.replace('/');
      // try {
      //   deleteToken(messaging);
      // } catch (error) {
      //   console.error(error);
      // }
    }
  });

  const logOut = () => {
    logOutRequest.post();
  };

  return {logOut, isLoading: logOutRequest.isLoading};
}
export default useLogOut;
