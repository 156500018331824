import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {UserProvider} from 'contexts/UserContext';
import {FilterProvider} from 'contexts/FilterContext';
import {MessageProvider} from 'contexts/MessageContext';
import Routes from 'routes';
import {ToastContainer} from 'react-toastify';

function App() {
  const queryClient = new QueryClient();

  return (
    <MessageProvider>
      <FilterProvider>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Routes />
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
            )}
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover
            />
          </QueryClientProvider>
        </UserProvider>
      </FilterProvider>
    </MessageProvider>
  );
}

export default App;
