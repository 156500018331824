import React, {CSSProperties} from 'react';

interface Props {
  count: number;
  activeIndex: number;
  style?: CSSProperties;
}

const PageIndicator: React.FC<Props> = ({count, activeIndex, style}) => {
  if (count <= 1) return <div className="h-1 flex w-full transition-all duration-200 bg-primary" />;
  return (
    <div className="flex justify-between" style={style}>
      {/*{[...Array(count).keys()].map((i) => (*/}
      {/*  <div*/}
      {/*    className={`h-1 flex w-2/5 transition-all duration-200 mx-2  ${*/}
      {/*      activeIndex === i ? "bg-primary" : "bg-white"*/}
      {/*    }`}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  );
};

export default PageIndicator;
