import React, {useState, SetStateAction, Dispatch, FC, createContext, useMemo} from 'react';
import type {MessageModal} from 'types/common';

interface MessageCtx {
  message: MessageModal | null;
  setMessage: Dispatch<SetStateAction<MessageModal | null>>;
}

const MessageContext = createContext<MessageCtx>(null as any);

export const MessageProvider: FC = ({children}) => {
  const [message, setMessage] = useState<MessageModal | null>(null);

  const messageProviderValue = useMemo(() => ({message, setMessage}), [message]);

  return <MessageContext.Provider value={messageProviderValue}>{children}</MessageContext.Provider>;
};

export default MessageContext;
