import React, {MouseEvent} from 'react';
import {Spinner} from 'components';

interface Props {
  children: string;
  isLoading?: boolean;
  primary?: boolean;
  spinnerColor?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}

const TextButton: React.FC<Props> = ({
  children,
  onClick,
  type = 'button',
  primary,
  className,
  spinnerColor = '!bg-primary',
  isLoading
}) => {
  return (
    <button
      type={type}
      className={`text-sm ${primary ? 'text-primary' : 'text-secondary'} ${className}`}
      onClick={onClick}>
      {isLoading ? <Spinner color={spinnerColor} /> : children}
    </button>
  );
};

export default TextButton;
