import React, {FC, MouseEvent, useCallback, useState} from 'react';
import {Button} from 'components';
import {usePost} from 'hooks';
import parse from 'html-react-parser';
import clsx from 'clsx';
import {getDateFromIsoString} from 'utils';
import {Link} from 'react-router-dom';
import type {CompanextMessageItem} from 'types/messaging';

interface Props {
  data: CompanextMessageItem;
}

const CompanextMessageCard: FC<Props> = ({data}) => {
  const [expandedText, setExpandedText] = useState<boolean>(true);
  const readMessage = usePost({
    url: 'companext_messages/{id}/read',
    method: 'GET'
  });

  const collapseExpanded = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setExpandedText((prevState) => !prevState);
  }, []);

  return (
    <Link
      to={`/product/${data?.product?.id}`}
      className={clsx(
        {'bg-gray-300 rounded-lg border my-4 flex border-gray-400 pt-4': !data?.read && readMessage?.isSuccess},
        {'rounded-lg flex pt-4 my-4 rounded-lg border': data?.read || !readMessage?.isSuccess}
      )}>
      <div className="w-1/6 flex items-start justify-center">
        <img className="w-12 h-12 rounded-full border" alt="product" src={data?.product?.avatar} />
      </div>
      <div className="w-5/6 flex border-b border-gray-300 pb-1 pr-1 text-pen">
        <div className="w-5/6">
          <div className={clsx('fade-in fade-in-down', {'line-clamp-3': expandedText})}>{parse(data.message)}</div>
          {data?.message?.length > 100 && (
            <button type="button" className="text-green-600 cursor-pointer my-2" onClick={collapseExpanded}>
              {expandedText ? 'more' : 'less'}
            </button>
          )}
          <p className="py-6 text-indigo-700">{data?.product?.name}</p>
        </div>
        <div className="w-1/6 flex flex-col  items-center justify-center">
          <p className="text-sm text-gray-600 py-4">{getDateFromIsoString(data?.created_at)}</p>
          {!data?.read && readMessage?.isSuccess && (
            <Button
              fill
              small
              className="w-full"
              isLoading={readMessage?.isLoading}
              onClick={() => readMessage.post(data)}>
              New
            </Button>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CompanextMessageCard;
