import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {Button, Modal, TextArea} from 'components';
import {usePost} from 'hooks';
import {MessageModalInfo} from 'types/modalInfo';
import {useForm} from 'react-hook-form';

interface refProps {
  open: (messageInfo: MessageModalInfo) => void;
  close: () => void;
}

interface Props {
  ref: RefObject<refProps>;
}

const SendMessageModal: ForwardRefRenderFunction<refProps, Props> = ({ref}, forwardedRef: ForwardedRef<refProps>) => {
  const [messageInfo, setMessageInfo] = useState<MessageModalInfo | undefined>(undefined);

  useImperativeHandle(forwardedRef, () => ({
    open(messageInfo: MessageModalInfo) {
      setMessageInfo(messageInfo);
    },
    close() {
      setMessageInfo(undefined);
    }
  }));

  const {control, handleSubmit} = useForm();

  const postMessage = usePost({
    url: 'send-message',
    successMessage: 'Your Message Sent Successfully.'
  });

  const onSubmit = (data: any) => {
    if (messageInfo?.hasComment)
      postMessage.post({
        message: data?.message,
        company_id: messageInfo?.url === 'companies' ? messageInfo?.id : undefined,
        product_id: messageInfo?.url === 'products' ? messageInfo?.id : undefined
      });
  };

  const onClose = useCallback(() => setMessageInfo(undefined), []);

  return (
    <Modal onClose={onClose}>
      <form className="bg-white  w-11/12 md:w-1/2 rounded shadow-xl p-8" onSubmit={handleSubmit(onSubmit)}>
        <TextArea name="message" control={control} placeholder="Write Your Message ..." />
        <Button fill className="my-4" small type="submit" isLoading={postMessage.isLoading}>
          Send
        </Button>
      </form>
    </Modal>
  );
};

export default forwardRef(SendMessageModal);
