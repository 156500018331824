import React, {ElementRef, memo, MouseEvent, useCallback, useRef} from 'react';
import {Button} from 'components';
import {useHistory} from 'react-router-dom';
import {defaultCompanyImg} from 'assets';
import type {SaveItemListItem} from 'types/bookmark';
import {BookmarkMoveModal} from 'containers';

interface Props {
  bookmark?: SaveItemListItem;
  tabID: number;
}

const BookmarkItemCard: React.FC<Props> = ({bookmark, tabID}) => {
  const bookmarkMoveModalRef = useRef<ElementRef<typeof BookmarkMoveModal>>(null);
  const history = useHistory();

  const onShowMoveModal = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (bookmarkMoveModalRef?.current?.open) bookmarkMoveModalRef.current.open();
  }, []);

  const goToProduct = () => {
    history.push(`${bookmark?.company_name ? 'product' : 'company'}/${bookmark?.reference_id}`);
  };

  return (
    <div className="my-2 flex cursor-pointer" onClick={goToProduct}>
      <div className="w-2/12 flex justify-start items-start lg:pl-4 cursor-pointer">
        <img
          src={bookmark?.avatar || defaultCompanyImg}
          className="rounded-full border border-gray-600 w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20"
          alt="company"
          onError={(e) => {
            e.currentTarget.src = defaultCompanyImg;
          }}
        />
      </div>
      <div className="w-10/12 md:ml-0 pb-4 border-b border-gray-400 flex-1 flex">
        <div className="flex-7">
          <p className="text-secondary text-left md:text-lg font-bold" dir="auto">
            {bookmark?.name}
          </p>
          {bookmark?.tagline && <p className="text-sm text-left text-gray-600">{bookmark?.tagline}</p>}
          {bookmark?.company_name && (
            <p className="text-sm text-left text-green-500" dir="auto">
              {bookmark?.company_name}
            </p>
          )}
          {bookmark?.reference_type === 'company' && (
            <p className="text-xs text-left text-gray-600" dir="auto">
              {`${bookmark?.product_count} Products/Services`}
            </p>
          )}
        </div>
        <div className="flex-3 flex flex-col items-center">
          <Button fill small onClick={onShowMoveModal}>
            Move To
          </Button>
        </div>
        <BookmarkMoveModal ref={bookmarkMoveModalRef} bookmark={bookmark} tabID={tabID} />
      </div>
    </div>
  );
};
export default memo(BookmarkItemCard);
