import React, {FC} from 'react';
import {MdErrorOutline} from 'react-icons/md';
import {TextButton} from 'components';
import clsx from 'clsx';

interface Props {
  className?: string;
  center?: boolean;
  onTryAgain?(): void;
}

const ServerError: FC<Props> = ({center = true, className, onTryAgain = () => {}}) => {
  return (
    <div className={clsx('flex flex-col', {'justify-center items-center': center}, className)}>
      <MdErrorOutline className="text-red-600 text-4xl" />
      <p className="my-4 text-sm ">serverError</p>
      <TextButton onClick={onTryAgain}>Try Again</TextButton>
    </div>
  );
};

export default ServerError;
