import React, {ChangeEvent, FC, useCallback, useRef} from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import {usePost} from 'hooks';
import {Button} from 'components';
import first from 'lodash/first';

interface Props {
  onUploadImage: (data: {path: string; file: any}) => void;
  changeUploadingStatus?(uploadStatus: boolean): void;
}

const ImageUploadInput: FC<Props> = ({onUploadImage, changeUploadingStatus}) => {
  const uploaderRef: any = useRef();

  const uploadImage = usePost({
    url: 'file-upload',
    isMultipart: true,
    onSuccess(response: any, request: any) {
      if (changeUploadingStatus) changeUploadingStatus(false);
      onUploadImage({path: response, file: request?.body?.get('file')});
    },
    onError() {
      if (changeUploadingStatus) changeUploadingStatus(false);
    }
  });

  const changeImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e?.target?.files);
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'product_pictures');
      if (changeUploadingStatus) changeUploadingStatus(true);
      uploadImage.post(formData);
    }
  };

  const onClickImage = useCallback(() => {
    if (uploaderRef?.current?.click) uploaderRef.current.click();
  }, []);

  return (
    <div className="flex justify-center items-center flex-col">
      <input
        onChange={changeImageHandler}
        ref={uploaderRef}
        className="input"
        accept="image/x-png,image/jpeg,image/gif"
        type="file"
        style={{display: 'none'}}
      />
      <Button
        success
        small
        fill
        isLoading={uploadImage?.isLoading}
        onClick={onClickImage}
        className="bg-blue-500 mt-4 rounded-full px-6 py-2 md:px-6 md:py-2 border-0 text-white flex-center shadow-md">
        Add Image
        <AiOutlinePlus className="text-white ml-2" size={25} />
      </Button>
    </div>
  );
};
export default ImageUploadInput;
