import React, {FC} from 'react';
import {ViewTypeButton, AvatarInput, TextEnableEdit} from 'components';
import {Control} from 'react-hook-form';
import type {CompanyDataApi} from 'types/company';

interface Props {
  data?: CompanyDataApi;
  isAdminView: boolean;
  onViewChange(): void;
  control: Control<any>;
}

const EditableCompanyHeader: FC<Props> = ({data, isAdminView, onViewChange, control}) => {
  return (
    <div className="fade-in">
      <div className="bg-primary w-100 h-32">
        <div className="flex mx-5 py-4 justify-center md:justify-start">
          <ViewTypeButton title="View as Admin" onClick={() => !isAdminView && onViewChange()} isActive={isAdminView} />
          <ViewTypeButton
            title="View as Member"
            isActive={!isAdminView}
            onClick={() => isAdminView && onViewChange()}
          />
        </div>
      </div>
      <div className="md:flex">
        <AvatarInput
          name="profile"
          type="company_avatar"
          control={control}
          defaultValue={data?.avatar}
          disabled={!isAdminView}
        />
        <div className="flex-7 py-4">
          <TextEnableEdit
            name="name"
            control={control}
            enableEdit={isAdminView}
            textClassName="!text-xl font-bold"
            defaultValue={data?.name}
          />
          <TextEnableEdit
            name="tagline"
            control={control}
            enableEdit={isAdminView}
            containerClassName="mt-2"
            defaultValue={data?.tagline}
          />
        </div>
      </div>
    </div>
  );
};

export default EditableCompanyHeader;
