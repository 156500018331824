import React, {FC} from 'react';
import {ProductCard, ListFooter, FetchContainer} from 'components';
import {useInfinite} from 'hooks';
import map from 'lodash/map';
import type {ProductListItem} from 'types/products';

interface props {
  onShowLogin: () => void;
}

const ProductsList: FC<props> = ({onShowLogin}) => {
  const fetchProducts = useInfinite({
    name: 'products',
    url: 'products/paginate',
    enabled: true
  });

  return (
    <FetchContainer
      isLoading={fetchProducts?.isFetching && !fetchProducts?.isFetchingNextPage && !fetchProducts?.data}
      isError={fetchProducts?.isError}
      onReFetch={fetchProducts?.refetch}>
      <div className="fade-in">
        {map(fetchProducts?.data, (product: ProductListItem) => (
          <ProductCard data={product} key={product.id} showLogin={onShowLogin} isHomeProducts />
        ))}
        <ListFooter
          onFetchMoreClick={fetchProducts?.fetchNextPage}
          canFetchMore={fetchProducts?.hasNextPage}
          isFetchingMore={fetchProducts?.isFetchingNextPage}
        />
      </div>
    </FetchContainer>
  );
};

export default ProductsList;
