import React, {FC} from 'react';
import clsx from 'clsx';
import {Control, useController} from 'react-hook-form';

interface Props {
  name: string;
  placeholder?: string;
  className?: string;
  control: Control<any>;
  defaultValue?: string | number | string[];
  containerClassName?: string;
  rows?: number;
}

const TextArea: FC<Props> = ({name, control, defaultValue, placeholder, className, containerClassName, rows}) => {
  const {
    field,
    fieldState: {error}
  } = useController({name, control, defaultValue});

  return (
    <div>
      <div className={containerClassName}>
        <textarea
          {...field}
          dir="auto"
          rows={rows}
          placeholder={placeholder}
          className={clsx(
            'border rounded-lg w-full p-4 py-2 focus:border-primary transition duration-200',
            {'border-red-500" : "border-gray-400': error},
            className
          )}
        />
      </div>
      {error?.message && <span className="text-red-500 text-sm mx-4 ">{error?.message}</span>}
    </div>
  );
};

export default TextArea;
