import React, {ChangeEvent, FC, useCallback, useRef} from 'react';
import {AiOutlineDelete, AiOutlinePlus} from 'react-icons/ai';
import {usePost} from 'hooks';
import {Button} from 'components';
import {Control, useController} from 'react-hook-form';
import map from 'lodash/map';
import concat from 'lodash/concat';
import cloneDeepWith from 'lodash/cloneDeepWith';
import pullAt from 'lodash/pullAt';
import first from 'lodash/first';
import clsx from 'clsx';
import {convertImageUrlToPath} from 'utils';

interface Props {
  name: string;
  type: string;
  titleClassName?: string;
  title: string;
  disabled?: boolean;
  defaultValue?: (string | undefined)[];
  control: Control<any>;
}

const AvatarInput: FC<Props> = ({name, title, type, titleClassName, disabled, defaultValue, control}) => {
  const uploaderRef: any = useRef();

  const {
    field: {onChange, value},
    fieldState: {error}
  } = useController({
    name,
    control,
    defaultValue: map(defaultValue, (image: string) => ({
      path: convertImageUrlToPath(image),
      url: image
    }))
  });

  const uploadImage = usePost({
    url: 'file-upload',
    isMultipart: true,
    onSuccess(response: any, request: any) {
      onChange(concat(value || [], {path: response, file: request?.body?.get('file')}));
    }
  });

  const changeImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e?.target?.files);
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      uploadImage.post(formData);
    }
  };

  const onClickImage = useCallback(() => {
    if (uploaderRef?.current?.click) uploaderRef.current.click();
  }, []);

  const onDeleteImage = (index: number) => {
    const filterImages = cloneDeepWith(value, (copyImages) => {
      pullAt(copyImages, index);
      return copyImages;
    });
    onChange(filterImages);
  };

  return (
    <div>
      <p className={clsx('text-black', titleClassName)}>{title}</p>
      <div className="flex justify-end w-full">
        {!disabled && (
          <Button
            success
            small
            fill
            isLoading={uploadImage?.isLoading}
            onClick={onClickImage}
            className="bg-blue-500 rounded-full px-6 py-2 md:px-6 md:py-2 border-0 text-white flex-center shadow-md">
            Add Image
            <AiOutlinePlus className="text-white ml-2" size={25} />
          </Button>
        )}
      </div>
      <input
        onChange={changeImageHandler}
        ref={uploaderRef}
        className="input"
        accept="image/x-png,image/jpeg,image/gif"
        type="file"
        style={{display: 'none'}}
      />
      {map(value, (item: any, index: number) => (
        <div className="w-full border rounded-lg my-4 relative" key={index}>
          <img
            src={item.file ? URL.createObjectURL(item.file) : item.url}
            alt="gallery"
            className="w-full h-auto object-fill rounded-lg"
          />
          {!disabled && (
            <button
              type="button"
              className="absolute bg-red-500 rounded-full px-3 py-1  text-white cursor-pointer flex-center shadow-md text-sm"
              onClick={() => onDeleteImage(index)}
              style={{bottom: 10, right: 20}}>
              Remove
              <AiOutlineDelete className="text-white ml-1" size={25} />
            </button>
          )}
        </div>
      ))}
      {error?.message && <p className="text-red-500 text-sm mx-4 ">{error?.message}</p>}
    </div>
  );
};

export default AvatarInput;
