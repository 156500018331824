import React, {useEffect, MouseEvent} from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  id: string;
  onCloseRequested(): void;
  noClickId?: string[];
}

const OutSideCloserDiv: React.FC<Props> = ({onCloseRequested, isOpen, id, children, noClickId = [], ...props}) => {
  useEffect(() => {
    const clickHandler = (e: MouseEvent<any>) => {
      e.stopPropagation();
      const menuDiv = document.getElementById(id);

      if (e.target !== menuDiv && !noClickId.includes((e.target as any)?.id)) {
        onCloseRequested();
      }
    };
    //detect outside clice
    if (isOpen) {
      document.onclick = (e: any) => clickHandler(e);
    } else {
      document.onclick = null;
    }
    return () => {
      document.onclick = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <div {...props} id={id}>
      {children}
    </div>
  );
};

export default OutSideCloserDiv;
