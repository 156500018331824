import React, {CSSProperties, FC} from 'react';
import map from 'lodash/map';
import clsx from 'clsx';

type TabItem = {
  key: string;
  label: string;
};

interface Props {
  items: TabItem[];
  activeIndex: number | string;
  onIndexChange(index: number): void;
  style?: CSSProperties;
  className?: string;
  productCount?: number;
}

const TabHeader: FC<Props> = ({items, activeIndex, onIndexChange, style, className, productCount}) => {
  return (
    <div className={clsx('flex justify-between h-12', className)} style={style}>
      {map(items, (tab: TabItem, index: number) => (
        <div
          key={tab?.key}
          onClick={() => {
            onIndexChange(index);
          }}
          className={clsx('flex flex-1 tabitem relative justify-center items-center cursor-pointer border-b', {
            activeTab: +activeIndex === index
          })}>
          <p className="text-l text-gray-600">{tab.label}</p>
          {tab.key === 'products' && !!productCount && (
            <span className=" rounded-full text-xs pt-1 cursor-pointer text-center text-white w-6 h-6 mb-3 bg-transparent !bg-primary border-0 right-0 top-0">
              {productCount}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TabHeader;
