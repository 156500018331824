import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import {useHistory} from 'react-router-dom';
import {TextButton, OutsideView} from 'components';
import {useLogOut} from 'hooks';

interface refProps {
  open: () => void;
  close: () => void;
}

interface Props {
  ref?: RefObject<refProps>;
  onClose?: () => void;
}

const ProfileMenu: ForwardRefRenderFunction<refProps, Props> = (
  {ref, onClose},
  forwardedRef: ForwardedRef<refProps>
) => {
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(forwardedRef, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));
  const {logOut} = useLogOut();
  const history = useHistory();

  const closeMenu = useCallback(() => {
    setOpen(false);
    if (onClose) onClose();
  }, []);

  if (!open) return null;

  return (
    <OutsideView onOutsideClick={closeMenu}>
      <div
        className="absolute z-50 w-32 shadow-xl bg-white border border-gray-300 rounded-lg flex flex-col fade-in-down"
        style={{right: 5}}>
        <TextButton
          onClick={() => history.push('/panel/setting')}
          className="text-left p-4 text-gray-900 border-b-2 border-gray-300 hover:text-primary">
          Setting
        </TextButton>
        <TextButton onClick={logOut} className="text-left p-4 text-gray-900 hover:text-primary">
          Sign Out
        </TextButton>
      </div>
    </OutsideView>
  );
};
export default forwardRef(ProfileMenu);
