import React, {FC} from 'react';
import {Button, SelectBox, TextArea, TextBox, ImageListInput} from 'components';
import {EditableSocialMedia} from 'containers';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import type {Control} from 'react-hook-form';
import type {CompanyDataApi} from 'types/company';
import clsx from 'clsx';

interface Props {
  data?: CompanyDataApi;
  isAdminView: boolean;
  control: Control<any>;
  isLoading?: boolean;
}

const EditableAboutTab: FC<Props> = ({data, isAdminView, control, isLoading}) => {
  return (
    <div className="fade-in mt-4">
      {isAdminView ? (
        <TextArea name="description" control={control} rows={5} defaultValue={data?.description} />
      ) : (
        <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line my-4">
          {data?.description}
        </p>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Contact</p>
          </div>
          <div className="flex-7">
            <TextArea name="contact" control={control} defaultValue={data?.contact} />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Contact</p>
          </div>
          <div className="flex-7">
            <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
              {data?.contact}
            </p>
          </div>
        </div>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Website</p>
          </div>
          <div className="flex-7">
            <TextBox name="website" control={control} defaultValue={data?.website} />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Website</p>
          </div>
          <div className="flex-7">
            <a target="_blank" href={data?.website} rel="noopener noreferrer">
              {data?.website}
            </a>
          </div>
        </div>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Industry</p>
          </div>
          <div className="flex-7">
            <SelectBox
              name="industries"
              url="industries"
              urlName="industries"
              control={control}
              defaultValue={data?.industries}
              isSearchable={false}
              isClearable
              mode="multiple"
            />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Industry</p>
          </div>
          <div className="flex-7">
            <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
              {map(data?.industries, 'name').join(',')}
            </p>
          </div>
        </div>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Company Type</p>
          </div>
          <div className="flex-7">
            <SelectBox
              control={control}
              name="types"
              urlName="types"
              url="types"
              defaultValue={data?.types}
              mode="multiple"
              isSearchable={false}
              isClearable
            />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Company Type</p>
          </div>
          <div className="flex-7">
            <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
              {map(data?.types, 'name').join(',')}
            </p>
          </div>
        </div>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Selected Customers</p>
          </div>
          <div className="flex-7">
            <TextBox control={control} name="customers" defaultValue={data?.customers} />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Selected Customers</p>
          </div>
          <div className="flex-7">
            <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
              {data?.customers}
            </p>
          </div>
        </div>
      )}
      {isAdminView ? (
        <div className="md:flex my-4 items-center">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black font-bold text-sm">Certificates</p>
          </div>
          <div className="flex-7">
            <TextBox control={control} name="certificates" defaultValue={data?.certificates} />
          </div>
        </div>
      ) : (
        <div className="md:flex my-4">
          <div className="flex-3 mb-1 md:mb-0">
            <p className="text-black">Certificates</p>
          </div>
          <div className="flex-7">
            <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
              {data?.certificates}
            </p>
          </div>
        </div>
      )}
      {isAdminView ? (
        <EditableSocialMedia control={control} data={data?.social_networks || []} />
      ) : (
        !isEmpty(data?.social_networks) && (
          <div className="md:flex my-4">
            <div className="flex-3 mb-1 md:mb-0">
              <p className="text-black">Social Media</p>
            </div>
            <div className="flex-7">
              <p dir="auto" className="text-base md:text-md text-gray-600 whitespace-pre-line mb-4">
                {map(data?.social_networks, (item) => (
                  <p>
                    <a href={`${item?.link}${item?.company_link}`} target="_blank" rel="noopener noreferrer">
                      {item?.name}
                    </a>
                  </p>
                ))}
              </p>
            </div>
          </div>
        )
      )}
      <ImageListInput
        name="gallery"
        type="product_pictures"
        title="Gallery"
        defaultValue={map(data?.gallery, 'path_url')}
        disabled={!isAdminView}
        control={control}
        titleClassName={clsx(
          'text-black',
          {'font-bold text-sm': isAdminView},
          {hidden: !isAdminView && isEmpty(data?.gallery)}
        )}
      />
      {isAdminView && (
        <div className="my-8 flex-center">
          <Button type="submit" isLoading={isLoading} fill className="!p-2 text-lg">
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};
export default EditableAboutTab;
